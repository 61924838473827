import { takeEvery, all, call, put } from 'redux-saga/effects';
import { vehicleSlice } from '../store';
import { VehicleService } from '../services';

const { createVehicle, createVehicleFailure, fetchAllVehicles, fetchAllVehiclesSuccess, fetchAllVehiclesFailure, updateVehicle, updateVehicleFailure, deleteVehicle, deleteVehicleFailure } = vehicleSlice.actions;

function* fetchVehiclesSaga() {
  try {
    const vehicles = (yield call(VehicleService.getAll)).data;
    yield put(fetchAllVehiclesSuccess(vehicles));
  } catch(ex) {
    yield put(fetchAllVehiclesFailure(ex?.response?.data?.message || "error"));
  }
}

function* updateVehicleSaga(action) {
  try {
    yield call(VehicleService.updateVehicles, action.payload.id, action.payload.data);
    const vehicles = (yield call(VehicleService.getAll)).data;
    yield put(fetchAllVehiclesSuccess(vehicles));
    if (action.payload.redirectFun) {
      action.payload.redirectFun();
    }
  } catch(ex) {
    yield put(updateVehicleFailure(ex?.response?.data?.message || "error"));
  }
}

function* deleteVehicleSaga(action) {
  try {
    yield call(VehicleService.deleteVehicles, action.payload.id, action.payload.data);
    const vehicles = (yield call(VehicleService.getAll)).data;
    yield put(fetchAllVehiclesSuccess(vehicles));
  } catch(ex) {
    yield put(deleteVehicleFailure(ex?.response?.data?.message || "error"));
  }
}

function* createVehicleSaga(action) {
  try {
    yield call(VehicleService.createNewVehicles, action.payload.data);
    const vehicles = (yield call(VehicleService.getAll)).data;
    yield put(fetchAllVehiclesSuccess(vehicles));
    if (action.payload.redirectFun) {
      action.payload.redirectFun();
    }
  } catch(ex) {
    yield put(createVehicleFailure(ex?.response?.data?.message || "error"));
  }
}

export function* vehicleEffect() {
  yield all([
    yield takeEvery(fetchAllVehicles.type, fetchVehiclesSaga),
    yield takeEvery(updateVehicle.type, updateVehicleSaga),
    yield takeEvery(createVehicle.type, createVehicleSaga),
    yield takeEvery(deleteVehicle.type, deleteVehicleSaga)
  ])
};