import { takeEvery, all, call, put } from 'redux-saga/effects';
import { userSlice } from './../store';
import { UserService } from './../services';

const { fetchAllUsers, fetchAllUsersSuccess, fetchAllUsersFailure } = userSlice.actions;

function* fetchUsersSaga() {
  try {
    const users = (yield call(UserService.getAll)).data;
    yield put(fetchAllUsersSuccess(users));
  } catch(ex) {
    yield put(fetchAllUsersFailure(ex));
  }
}

export function* userEffect() {
  yield all([
    yield takeEvery(fetchAllUsers.type, fetchUsersSaga)
  ])
};