export const EMPLOYEE_TITLE = {
	CEO: 'CEO',
	HR: 'HR',
	OPRATIONS_DIRECTOR: 'Operations Manager',
	OPERATIONS_STAFF: 'Operations Staff',
	EVENTS_DIRECTOR: 'Events Director',
	EVENTS_STAFF: 'Events Staff',
	TRANSPORTATION_DIRECTOR: 'Transportation Director',
	TRANSPORTATION_DISPATCHER: 'Transportation Dispatcher',
	DRIVER: 'Driver',
	LOBBY_MANAGER: 'Lobby Manager',
	LOBBY_SERVER: 'Lobby Server',
	CHEF: 'CHEF',
	KITCHEN_ASSISTANT: 'Kitchen Assistant',
	SOCIAL_WORKER_MANAGER: 'Social Worker Manager',
	SOCIAL_WORKER: 'Social Worker',
	HEAD_NURSE: 'Head Nurse',
	NURSE: 'Nurse',
	NURSE_ASSISTANT: 'Nurse Assistant',
	CLINICAL_FRONT_DESK: 'Clinical Front Desk'
};

export const EMPLOYEE_TITLE_CN = {
	CEO: '总经理',
	HR: '人力资源',
	OPRATIONS_DIRECTOR: '行政主管',
	OPERATIONS_STAFF: '行政人员',
	EVENTS_DIRECTOR: '活动主管',
	EVENTS_STAFF: '活动人员',
	TRANSPORTATION_DIRECTOR: '交通主管',
	TRANSPORTATION_DISPATCHER: '交通协调',
	DRIVER: '司机',
	LOBBY_MANAGER: '大堂主管',
	LOBBY_SERVER: '大唐服务',
	CHEF: '主厨',
	KITCHEN_ASSISTANT: '厨房协助',
	SOCIAL_WORKER_MANAGER: '社工主管',
	SOCIAL_WORKER: '社工',
	HEAD_NURSE: '护士长',
	NURSE: '护士',
	NURSE_ASSISTANT: '护士助理',
	CLINICAL_FRONT_DESK: '约诊'
};

export const EMPLOYEE_ROLES = {
	ADMIN: 'admin',
	DRIVER: 'driver',
	EMPLOYEE_VIEWER: 'employeeViewer',
	EMPLOYEE_EDITOR: 'employeeEditor',
	CUSTOMER_VIEWER: 'customerViewer',
	CUSTOMER_EDITOR: 'customerEditor',
	MEDICAL_RESERVATION_INFO_VIEWER: 'medicalReservationInfoViewer',
	MEDICAL_RESERVATION_INFO_EDITOR: 'medicalReservationInfoEditor',
	MEDICAL_RESERVATION_SCHEDULE_VIEWER: 'medicalReservationScheduleViewer',
	MEDICAL_RESERVATION_SCHEDULE_EDITOR: 'medicalReservationScheduleEditor',
	ATTENDANCE_VIEWER: 'attendanceViewer',
	ATTENDANCE_EDITOR: 'attendanceEditor',
	DRIVER_VIEWER: 'driverViewer',
	DRIVER_EDITOR: 'driverEditor',
	VEHICLE_VIEWER: 'vehicleViewer',
	VEHICLE_EDITOR: 'vehicleEditor',
	ROUTE_VIEWER: 'routeViewer',
	ROUTE_EDITOR: 'routeEditor',
	RESOURCE_LIST_VIEWER: 'resourceListViewer',
	RESOURCE_LIST_EDITOR: 'resourceListEditor'
};

export const EMPLOYEE_TITLE_ROLES_MAP = {
	CEO: [EMPLOYEE_ROLES.ADMIN],
	HR: [EMPLOYEE_ROLES.ADMIN],
	OPRATIONS_DIRECTOR: [EMPLOYEE_ROLES.EMPLOYEE_EDITOR, EMPLOYEE_ROLES.EMPLOYEE_VIEWER, EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER, EMPLOYEE_ROLES.DRIVER_EDITOR, EMPLOYEE_ROLES.DRIVER_VIEWER, EMPLOYEE_ROLES.VEHICLE_EDITOR, EMPLOYEE_ROLES.VEHICLE_VIEWER],
	OPERATIONS_STAFF: [EMPLOYEE_ROLES.EMPLOYEE_VIEWER, EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER],
	EVENTS_DIRECTOR: [EMPLOYEE_ROLES.EMPLOYEE_VIEWER, EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER],
	EVENTS_STAFF: [EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER],
	TRANSPORTATION_DIRECTOR: Object.values(EMPLOYEE_ROLES).filter((val) => val !== EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_EDITOR && val !== EMPLOYEE_ROLES.ADMIN),
	TRANSPORTATION_DISPATCHER: [EMPLOYEE_ROLES.ROUTE_VIEWER, EMPLOYEE_ROLES.ROUTE_EDITOR,EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_EDITOR, EMPLOYEE_ROLES.ATTENDANCE_VIEWER, EMPLOYEE_ROLES.DRIVER_VIEWER, EMPLOYEE_ROLES.VEHICLE_EDITOR, EMPLOYEE_ROLES.VEHICLE_VIEWER, EMPLOYEE_ROLES.RESOURCE_LIST_VIEWER, EMPLOYEE_ROLES.DRIVER],
	DRIVER: [EMPLOYEE_ROLES.DRIVER],
	LOBBY_MANAGER: [EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_EDITOR],
	LOBBY_SERVER: [EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER],
	CHEF: [EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER],
	KITCHEN_ASSISTANT: [],
	SOCIAL_WORKER_MANAGER: [EMPLOYEE_ROLES.EMPLOYEE_VIEWER, EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER, EMPLOYEE_ROLES.DRIVER],
	SOCIAL_WORKER: [EMPLOYEE_ROLES.EMPLOYEE_VIEWER, EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER],
	HEAD_NURSE: [EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER, EMPLOYEE_ROLES.RESOURCE_LIST_EDITOR, EMPLOYEE_ROLES.RESOURCE_LIST_VIEWER],
	NURSE: [EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER, EMPLOYEE_ROLES.RESOURCE_LIST_VIEWER],
	NURSE_ASSISTANT:  [EMPLOYEE_ROLES.CUSTOMER_VIEWER,  EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_VIEWER,  EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER,  EMPLOYEE_ROLES.RESOURCE_LIST_VIEWER, EMPLOYEE_ROLES.ROUTE_VIEWER],
	CLINICAL_FRONT_DESK: [EMPLOYEE_ROLES.CUSTOMER_VIEWER, EMPLOYEE_ROLES.CUSTOMER_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_VIEWER, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_EDITOR, EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER, EMPLOYEE_ROLES.ATTENDANCE_VIEWER, EMPLOYEE_ROLES.RESOURCE_LIST_EDITOR, EMPLOYEE_ROLES.RESOURCE_LIST_VIEWER, EMPLOYEE_ROLES.ROUTE_VIEWER],
};

export const INVITATION_CODE = ['ws5801care', 'world911street'];

// // Test Site 01
// export const LEGACY_LINK = (window.location.hostname.includes('worldshine2.mayo.llc') || window.location.hostname.includes('site2')) ? 'http://worldshineretro2.mayo.llc/staff/login?user=bxia' : ((window.location.hostname.includes('worldshine3.mayo.llc') || window.location.hostname.includes('site3')) ? 'http://worldshineretro3.mayo.llc/staff/login?user=bxia': 'http://worldshineretro.mayo.llc/staff/login?user=leapon');
// export const LEGACY_LINK = (window.location.hostname.includes('ws2') || window.location.hostname.includes('site2')) ? 'http://wsretro2.mayosolution.com/staff/login?user=bxia' : ((window.location.hostname.includes('ws3') || window.location.hostname.includes('site3')) ? 'http://wsretro3.mayosolution.com/staff/login?user=bxia': 'http://wsretro1.mayosolution.com/staff/login?user=leapon');

// Test Site 02
// export const LEGACY_LINK = 'http://worldshineretro2.mayo.llc/staff/login?user=bxia';