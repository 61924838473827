import { createSlice, CaseReducer } from "@reduxjs/toolkit";

const initialState = {
  templates: [],
  loading: false,
  error: null
};


const fetchAllRouteTemplates = (state, action) => {
  state.loading = true;
};
const fetchAllRouteTemplatesSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.templates = action.payload;
};
const fetchAllRouteTemplatesFailure = (state, action) => {
  state.routes = [];
  state.error = action.payload;
};

const updateRouteTemplate = (state, action) => {};
const updateRouteTemplateFailure = (state, action) => {
  state.error = action.payload;
};

const createRouteTemplate = (state, action) => {console.log('test', action.payload)};
const createRouteTemplateFailure = (state, action) => {
  state.error = action.payload;
}

export const transRouteTemplatesSlice = createSlice({
  name: "transRouteTemplates",
  initialState,
  reducers: {
    fetchAllRouteTemplates,
    fetchAllRouteTemplatesSuccess,
    fetchAllRouteTemplatesFailure,
    updateRouteTemplate,
    updateRouteTemplateFailure,
    createRouteTemplate,
    createRouteTemplateFailure
  }
});

