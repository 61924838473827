import React, { useEffect, useState } from "react";
import { TransRoutesService } from "../../services";

const BreakfastSection = ({transRoutes, breakfastRecords, sectionName, confimHasBreakfast, removeBreakfastRecord}) => {
	const [customers, setCustomers] = useState([]);

	useEffect(() => {
		setCustomers(TransRoutesService.getAllInCenterCustomersFromRoutes(transRoutes, breakfastRecords))
	})

	
  
  return (
    <>
      <div className="text-primary mb-4"><h5>{sectionName}</h5></div>
      <div className="list row">
        <div className="col-md-12">
					<table className="personnel-info-table">
            <thead>
              <tr>
								<th>Name</th>
								<th>Has Breakfast Today</th>
								<th>Change Breakfast Status</th>
							</tr>
						</thead>
						<tbody>
							{
								customers?.map((customer) => (
									<tr className={customer?.has_breakfast ? 'light-green' : 'red'}>
										<td>{customer?.customer_name}</td>
										<td>{customer?.has_breakfast ? 'Yes': 'No'}</td>
										<td>
											{!customer?.has_breakfast && <button className="btn btn-link btn-sm" onClick={() => confimHasBreakfast(customer)}>Confirm Customer Has breakfast</button>}
											{customer?.has_breakfast && <button className="btn btn-link btn-sm" onClick={() => removeBreakfastRecord(customer?.customer_id)}>Mark Customer NOT have breakfast</button>}
										</td>
									</tr>
								))
							}
						</tbody>
					</table>
        </div>
      </div>
    </>
    
  );
};

export default BreakfastSection;