import http from "../http-common";
import { EMPLOYEE_TITLE_CN, EMPLOYEE_TITLE_ROLES_MAP } from "../shared";

const createNewDispatcher = (data) => {
  data.roles = EMPLOYEE_TITLE_ROLES_MAP.DRIVER;
  data.status = 'inactive';
  data.title = 'DRIVER';
  data.title_cn = EMPLOYEE_TITLE_CN.DRIVER;
	return http.post('/employees', data);
};


export const DispatcherService = {
  createNewDispatcher
};
