import http from "../http-common";
const getReportsByDateAndType = (date, type) => {
  const params = {};
  if (date) {
    params.date = date;
  }
  if (type) {
    params.type = type;
  }
  return http.get('/reports/search', {
    params
  });
};

const getReportsByRouteIdAndType = (route_id, type) => {
  const params = {};
  if (route_id) {
    params.route_id = route_id;
  }
  if (type) {
    params.type = type;
  }
  return http.get('/reports/search-route', {
    params
  }); 
}

const createReport = (data) => {
	return http.post('/reports', data);
};

const updateReport = (id, data) => {
  return http.put(`/reports/${id}`, data);
}



export const ReportService = {
  getReportsByDateAndType,
  createReport,
  updateReport,
  getReportsByRouteIdAndType
};
