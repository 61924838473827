import React, {useEffect, useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { vehicleSlice, selectVehicleError } from "./../../store";
import { AuthService } from "../../services";

const CreateVehicle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!AuthService.canAddOrEditVechiles()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an Dispatcher or admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
  }, []);
  const { createVehicle } = vehicleSlice.actions;
	const [vehicleNumber, setVehicleNumber] = useState();
	const [tag, setTag] = useState('');
	const [make, setMake] = useState('');
	const [vehicleModel, setVehicleModel] = useState('');
	const [year, setYear] = useState('');
	const [ezpass, setEzpass] = useState('');
	const [gps, setGps] = useState('');
	const [mileage, setMileage] = useState();
	const [capacity, setCapacity] = useState();
	const [checklist, setChecklist] = useState(['']);
	const error = useSelector(selectVehicleError);

  const redirectTo = () => {
    const params = new URLSearchParams(window.location.search);
		const redirect = params.get('redirect');
		if (redirect === 'schedule') {
			navigate(`/trans-routes/schedule`);
		} else {
			navigate(`/trans-routes/dashboard`);
		}
  }

  const addItemToArray = () => {
    const arr = [...checklist, ''];
    setChecklist(arr);
  }

	const saveVechile = () => {
		const data = {
			vehicle_number: vehicleNumber,
			tag,
			ezpass,
			gps_tag: gps,
			mileage,
			capacity,
			year,
			make,
			vehicle_model: vehicleModel,
			status: 'active',
			checklist
		};
		dispatch(createVehicle({data, redirectFun: redirectTo}));
	}

  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Create New Vehicle <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
				<div className="col-md-4 mb-4">
          <div>Vehicle Number(*):</div> <input type="number" value={vehicleNumber || ''} onChange={e => setVehicleNumber(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Tag(*):</div> <input type="text" value={tag || ''} onChange={e => setTag(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>EZ Pass:</div> <input type="text" value={ezpass || ''} onChange={e => setEzpass(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>GPS:</div> <input type="text" value={gps || ''} onChange={e => setGps(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
					<div>Make:</div> <input type="text" value={make || ''} onChange={e => setMake(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
					<div>Model:</div> <input type="text" value={vehicleModel || ''} onChange={e => setVehicleModel(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
					<div>Year:</div> <input type="text" value={year || ''} onChange={e => setYear(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Mileage(*):</div> <input type="number" value={mileage || ''} onChange={e => setMileage(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Capacity(*):</div> <input type="number" value={capacity || ''} onChange={e => setCapacity(e.target.value)}/>
        </div>
				<div className="col-md-12 mb-4">
					<div>Checklist(*):</div>
					{checklist.map((item, index) => (<div className="mb-4" key={index}><input type="text" value={item} onChange={(e) => setChecklist([...checklist].map((a, index1) => {if (index1 === index) {return e.target.value;} return a;}))}/>
						<button className="btn btn-link btn-sm" onClick={(e) => setChecklist([...checklist].filter((value, index1) => index1 != index))}>Remove</button>
					</div>))}
            <button className="btn btn-link" onClick={() => addItemToArray()}>+Add New Item</button>
				</div>
      </div>
      <div className="list row mb-5">
				{error && <div className="col-md-12 mb-4 alert alert-danger" role="alert">
					 {error}
				</div>}
        <div className="col-md-6 col-sm-6 col-xs-12">
          <button className="btn btn-primary btn-sm" onClick={() => saveVechile()}> Save </button>
          <button className="btn btn-default btn-sm" onClick={() => redirectTo()}> Cancel </button>
        </div>
      </div>
    </>
  );
};

export default CreateVehicle;