import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { AuthService, EventsService, CustomerService, DriverService, ResourceService } from "../../services";
import DatePicker from "react-datepicker";
import { Button, Modal } from "react-bootstrap";
import TimePicker from 'react-time-picker';

const EventsList = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sorting, setSorting] = useState({key: '', order: ''});
  const [selectedItems, setSelectedItems] = useState([]);
  const [showTransportationModal, setShowTransportationModal] = useState(false);
  const [driver, setDriver] = useState(null);
  const [driverOptions, setDriverOptions] = useState([]);
  const [transportStartTime, setTransportStartTime] = useState(null);
  const [transportOptionsList, setTransportationOptionsList] = useState([]);
  const [transportSelected, setTransportSelected] = useState(null);
  const [showDeletedItems, setShowDeletedItems] = useState(false);

  const columns = [
    {
      key: 'customer',
      label:'Customer'

    },
    {
      key: 'doctor',
      label: 'Doctor'

    },
    {
      key: 'phone',
      label: 'Phone'

    },
    {
      key: 'address',
      label: 'Address'

    },
    {
      key: 'translation',
      label: 'Translation'

    },
    {
      key: 'newPatient',
      label: 'New Patient',

    },
    {
      key: 'needId',
      label: 'Need ID'

    },
    {
      key: 'disability',
      label: 'Disability'

    },
    {
      key: 'startTime',
      label: 'Start Time'

    },
    {
      key: 'fasting',
      label: 'Fasting'

    },
    {
      key: 'transportation',
      label: 'Driver'
    }
  ];

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    CustomerService.getAllCustomers().then((data) => {
      setCustomers(data.data);
    })
    DriverService.getAllActiveDrivers().then((data) => {
      console.log('drivers', data.data);
      setDriverOptions(data.data);
    })
    ResourceService.getAll().then(data => {
      setResources(data.data);
    }) 
  }, []);

  useEffect(() => {
    if (customers?.length > 0 && resources?.length>0) {
      EventsService.getAllEvents({ date: EventsService.formatDate(selectedDate) }).then((data) => {
        setEvents(data.data.filter((item) => {
          item.customer = item?.data?.customer ? (customers.find(c=>c.id === item?.data?.customer)?.name || item?.data?.client_name || '') : (item?.data?.client_name || '');
          item.doctor = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.name || item?.data?.resource_name || '') : (item?.data?.resource_name || '');
          item.phone = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.phone || item?.data?.resource_phone || '') : (item?.data?.resource_phone || '');
          item.address = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.address || item?.data?.resource_address || '') : (item?.data?.resource_address || '');
          item.translation = item?.data?.interpreter || '';
          item.newPatient = item?.data?.new_patient || '';
          item.needId = item?.data?.need_id || '';
          item.disability = item?.data?.disability || '';
          item.startTime = item?.start_time? `${new Date(item?.start_time).toLocaleDateString()} ${new Date(item?.start_time).toLocaleTimeString()}` : '' ;
          item.fasting = item?.data?.fasting || '';
          item.transportation = item?.link_event_name || '';
          item.dob = item?.data?.customer ? customers.find(c => c.id === item?.data?.customer)?.birth_date : (item?.data?.client_birth_date || '');
          item.transMethod = item?.data?.trans_method;
          return item;
        }).filter(item => item.type === 'medical' && item.confirmed));
        setTransportationOptionsList(data.data.filter((item) => item.type === 'transportation' && item.status === 'active'))
      })
    }
  }, [selectedDate, resources, customers]);



  useEffect(() => {
    const newEvents = [...events];
    const sortedEvents = sorting.key === '' ? newEvents : newEvents.sort((a, b) => {
      return a[sorting.key].localeCompare(b[sorting.key]);
    });
    setEvents(
      sorting.order === 'asc' ? sortedEvents : sortedEvents.reverse()
    )
  }, [sorting]);


  const redirectToAdmin = () => {
    navigate(`/medical`)
  }

  const goToEdit = (id) => {
    navigate(`/medical/events/edit/${id}`)
  }

  const goToCreateNew = () => {
    navigate(`/medical/events`)
  }

  const goToEventsCalendar = () => {
    navigate(`/medical/events/calendar`)
  }

  const goToMultipleList = () => {
    navigate(`/medical/events/multiple-list`)
  }

  const goToResourceList = () => {
    navigate(`/medical/resources/list`)
  }


  const goToView = (id) => {
    navigate(`/medical/events/${id}`)
  }

  const goToNextDay = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  }

  const goToPreviousDay = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  }

  const getSortingImg = (key) => {
    return sorting.key === key ? (sorting.order === 'asc' ? 'up_arrow' : 'down_arrow') : 'default';
  }

  const sortTableWithField = (key) => {
    let newSorting = {
      key,
      order: 'asc',
    }
    
    if (sorting.key === key && sorting.order === 'asc') {
      newSorting = {...newSorting, order: 'desc'};
    }
    setSorting(newSorting);
  }

  const toggleSelectedAllItems = () => {
    if (selectedItems.length !== events.length || selectedItems.length === 0) {
      const newSelectedItems = [...events].map((event) => event.id);
      setSelectedItems(newSelectedItems);
    } else {
      setSelectedItems([]);
    }
  }

  const toggleItem  = (id) => {
    if (selectedItems.includes(id)) {
      const newSelectedItems = [...selectedItems].filter((item) => item !== id);
      setSelectedItems(newSelectedItems);
    } else {
      const newSelectedItems = [...selectedItems, id];
      setSelectedItems(newSelectedItems);
    }
  }

  const checkSelectAll = () => {
    return selectedItems.length === events.length && selectedItems.length > 0;
  }

  const disableAssignTransportationButton  = () => {
    return (!transportSelected || transportSelected === '') || ((transportSelected === 'create_new') && (!driver || !transportStartTime || driver === '' || transportStartTime === ''));
  }

  const closePanel = () => {
    setShowTransportationModal(false);
    setTransportStartTime(null);
    setDriver(null);
    setTransportSelected(null);
  }

  const assignDriver = () => {
    // if select create new event, then create a new transportation event first
    if (transportSelected === 'create_new') {
      const dateString = new Date().toLocaleDateString();
      const startDateTime = new Date(`${dateString} ${transportStartTime}`);
      const transportationParameter = {
        title: `${driverOptions.find((item) => item.id === driver)?.name} ${startDateTime.toLocaleTimeString()}`,
        description: 'transportatoin for med events',
        type: 'transportation',
        source_type: 'resource',
        source_uuid: '',
        target_type: 'staff',
        target_uuid: driver,
        start_time: startDateTime,
        stop_time: startDateTime,
        status: 'active',
        create_by: JSON.parse(localStorage.getItem('user'))?.name,
        create_date: new Date(),
        edit_by: JSON.parse(localStorage.getItem('user'))?.name,
        edit_date: new Date(),
        edit_history: [{
          employee: JSON.parse(localStorage.getItem('user'))?.name,
          date: new Date()
        }]
      };

      EventsService.createNewEvent(transportationParameter).then(data => {
        const trans = data.data;
        EventsService.assignTransportationToEvents({
          transportationId: trans.id,
          transportationName: trans.title,
          eventIds: selectedItems
        }).then(() => {
          setSelectedItems([]);
          EventsService.getAllEvents({ date: EventsService.formatDate(selectedDate) }).then((data) => {
            const results = [...data.data];
            const eventsResults = results.filter((item) => {
              item.customer = item?.data?.customer ? (customers.find(c=>c.id === item?.data?.customer)?.name || item?.data?.client_name || '') : (item?.data?.client_name || '');
              item.doctor = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.name || item?.data?.resource_name || '') : (item?.data?.resource_name || '');
              item.phone = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.phone || item?.data?.resource_phone || '') : (item?.data?.resource_phone || '');
              item.address = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.address || item?.data?.resource_address || '') : (item?.data?.resource_address || '');
              item.translation = item?.data?.interpreter || '';
              item.newPatient = item?.data?.new_patient || '';
              item.needId = item?.data?.need_id || '';
              item.disability = item?.data?.disability || '';
              item.startTime = item?.start_time? `${new Date(item?.start_time).toLocaleDateString()} ${new Date(item?.start_time).toLocaleTimeString()}` : '' ;
              item.fasting = item?.data?.fasting || '';
              item.transportation = item?.link_event_name || '';
              item.dob = item?.data?.customer ? customers.find(c => c.id === item?.data?.customer)?.birth_date : (item?.data?.client_birth_date || '');
              item.transMethod = item?.data?.trans_method;
              return item;
            }).filter(item => item.type === 'medical' && item.confirmed);
            const newEvents = [...eventsResults];
            const sortedEvents = sorting.key === '' ? newEvents : newEvents.sort((a, b) => {
              return a[sorting.key].localeCompare(b[sorting.key]);
            });
            setEvents(
              sorting.order === 'asc' ? sortedEvents : sortedEvents.reverse()
            )
            setTransportationOptionsList(data.data.filter((item) => item.type === 'transportation' && item.status === 'active'));
            closePanel();
          })
        });
      }).catch((err) => console.log('Transportation Event Creation failed'))
    } else {
      if (transportSelected && transportSelected !== '') {
        EventsService.assignTransportationToEvents({
          transportationId: transportSelected,
          transportationName: transportOptionsList.find((item) => item.id === transportSelected)?.title,
          eventIds: selectedItems
        }).then(() => {
          console.log(123123);
          setSelectedItems([]);
          EventsService.getAllEvents({ date: EventsService.formatDate(selectedDate) }).then((data) => {
            const results = [...data.data];
            const eventsResults = results.filter((item) => {
              item.customer = item?.data?.customer ? (customers.find(c=>c.id === item?.data?.customer)?.name || item?.data?.client_name || '') : (item?.data?.client_name || '');
              item.doctor = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.name || item?.data?.resource_name || '') : (item?.data?.resource_name || '');
              item.phone = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.phone || item?.data?.resource_phone || '') : (item?.data?.resource_phone || '');
              item.address = item?.data?.resource ? (resources.find(r=> r.id === item?.data?.resource)?.address || item?.data?.resource_address || '') : (item?.data?.resource_address || '');
              item.translation = item?.data?.interpreter || '';
              item.newPatient = item?.data?.new_patient || '';
              item.needId = item?.data?.need_id || '';
              item.disability = item?.data?.disability || '';
              item.startTime = item?.start_time? `${EventsService.formatDate(new Date(item?.start_time))} ${new Date(item?.start_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: 'numeric', hour12: true })}` : '' ;
              item.fasting = item?.data?.fasting || '';
              item.transportation = item?.link_event_name || '';
              item.dob = item?.data?.customer ? customers.find(c => c.id === item?.data?.customer)?.birth_date : (item?.data?.client_birth_date || '');
              item.transMethod = item?.data?.trans_method;
              return item;
            }).filter(item => item.type === 'medical' && item.confirmed);
            const newEvents = [...eventsResults];
            const sortedEvents = sorting.key === '' ? newEvents : newEvents.sort((a, b) => {
              return a[sorting.key].localeCompare(b[sorting.key]);
            });
            setEvents(
              sorting.order === 'asc' ? sortedEvents : sortedEvents.reverse()
            )
            // setTransportationOptionsList(data.data.filter((item) => item.type === 'transportation' && item.status === 'active'));
            closePanel();
          })
        });
      }
    };
  };
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Medical Event One-Day List (With Driver Assignment) <button className="btn btn-primary btn-sm" onClick={() => {goToCreateNew()}}>Create New Medical Event</button>  <button className="btn btn-link btn-sm" onClick={() => {redirectToAdmin()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-6 col-sm-12 mb-4">
          Pick A Date: <DatePicker selected={selectedDate} onChange={(v) => setSelectedDate(v)} />
        </div>
        <div className="col-md-12 col-sm-12 mb-4">
          <Button className="me-4" variant="outline-primary" onClick={() => goToPreviousDay()} > {'<'} </Button>
          <Button className="me-4" variant="outline-primary" onClick={() => goToNextDay()}> {'>'} </Button>
          <Button className="me-4" variant="primary" disabled={selectedItems.length === 0} onClick={() => setShowTransportationModal(true)}>Show Assign Transportation Panel</Button>
          Show Deleted Events: <input type="checkbox" value={showDeletedItems} checked={showDeletedItems === true} onClick={() => setShowDeletedItems(!showDeletedItems)} />
        </div>
        <div className="col-md-12">
          <table className="personnel-info-table medical"> 
            <thead>
              <tr>
                <th><input type="checkbox" checked={checkSelectAll()} onClick={() => toggleSelectedAllItems()}></input></th>
                {
                  columns.map((column, index) => <th className="sortable-header" key={index}>
                    {column.label}  <span onClick={() => sortTableWithField(column.key)}><img src={`/images/${getSortingImg(column.key)}.png`}></img></span>
                  </th>)
                }
                <th>Customer Date of Birth</th>
                <th>Transportation</th>
              </tr>
              
            </thead>
            <tbody>
              {
                events && (showDeletedItems ? events : events.filter(event => event.status === 'active')).map(medicalEvent => <tr key={medicalEvent.id}>
                  <td><input type="checkbox" checked={selectedItems.includes(medicalEvent.id)} onClick={()=>toggleItem(medicalEvent?.id)}/></td>
                  <td>{medicalEvent?.customer}</td>
                  <td>{medicalEvent?.doctor}</td>
                  <td>{medicalEvent?.phone}</td>
                  <td>{medicalEvent?.address}</td> 
                  <td>{medicalEvent?.translation}</td>
                  <td>{medicalEvent?.newPatient}</td>
                  <td>{medicalEvent?.needId}</td>
                  <td>{medicalEvent?.disability}</td>
                  <td>{medicalEvent?.startTime}</td>
                  <td>{medicalEvent?.fasting}</td>
                  <td>{medicalEvent?.transportation}</td>
                  <td>{medicalEvent?.dob}</td>
                  <td>{medicalEvent?.transMethod}</td>
                </tr>)
              }
            </tbody>
          </table>
           
        </div>
      </div>
      <Modal show={showTransportationModal} onHide={() => closePanel()}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Transportations Panel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div>
              Select Transportation: 
              <div>
                <select value={transportSelected} onChange={(e)=>{setTransportSelected(e.currentTarget.value)}}>
                  <option value=""></option>
                  <option value="create_new">Create A New Transportation</option>
                  {transportOptionsList.map((item) => <option key={item.id} value={item.id}>{item.title}</option>)}
                </select>
              </div>
             
            </div>
            {transportSelected === 'create_new' && (
              <>
                <hr/>
                <div>
                  Start Time:
                  <div>
                    <TimePicker disableClock={true} value={transportStartTime} onChange={setTransportStartTime} />
                  </div>
                </div>
                
              </>)
            }

            {transportSelected === 'create_new' && (
              <>
                <hr/>
                <div>
                  Driver:
                  <div>
                    <select value={driver} onChange={(e)=>{setDriver(e.currentTarget.value)}}>
                      <option value=""></option>
                      {
                        driverOptions.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)
                      }
                    </select>
                  </div>
                </div>
              </>)
            }
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closePanel()}>
            Close
          </Button>
          <Button disabled={disableAssignTransportationButton()} variant="primary" onClick={() => assignDriver()}>
            Assign Transportation
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

export default EventsList;