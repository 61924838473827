export const PICKUP_STATUS = {
  BOTH: 'both',
  PICKUP_ONLY: 'pickupOnly',
  DROPOFF_ONLY: 'dropoffOnly',
  SCHEDULE_ABSENT: 'scheduleAbsent'
}

export const PICKUP_STATUS_TEXT = {
	both: 'Both',
	pickupOnly: 'Pickup Only',
	dropoffOnly: 'Dropoff Only',
	scheduleAbsent: 'Schedule Absent'
}

export const CUSTOMER_TYPE = {
	MEMBER: 'member',
	VOLUNTEER: 'volunteer',
	SELF_PAY: 'selfPayMember',
	VISITOR: 'visitor',
	TRANSFERRED: 'transferred',
	DISCHARED: 'discharged',
	DECEASED: 'deceased'
}

export const CUSTOMER_TYPE_TEXT = {
	member: 'Member',
	volunteer: 'Volunteer',
	selfPayMember: 'Self-Pay Member',
	visitor: 'Visitor',
	transferred: 'Transferred',
	discharged: 'Discharged',
	deceased: 'Deceased'
}