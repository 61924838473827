import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { AuthService, EventsService, CustomerService, ResourceService, EventRequestsService } from "../../services";

const EventRequestList = () => {
  const navigate = useNavigate();
  const [eventRequests, setEventRequests] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [resources, setResources] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(new Date());
  const [sorting, setSorting] = useState({key: '', order: ''});
	const [showDone, setShowDone] = useState(false);
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [showTransportationModal, setShowTransportationModal] = useState(false);
//   const [driver, setDriver] = useState(null);
//   const [driverOptions, setDriverOptions] = useState([]);
//   const [transportStartTime, setTransportStartTime] = useState(null);
//   const [transportOptionsList, setTransportationOptionsList] = useState([]);
//   const [transportSelected, setTransportSelected] = useState(null);
//   const [showDeletedItems, setShowDeletedItems] = useState(false);
const columns = [
	{
		key: 'customer_display',
		label:'Patient'

	},
	{
		key: 'resource_display',
		label: 'Doctor'

	},
	{
		key: 'source',
		label: 'Source'

	},
	{
		key: 'type',
		label: 'Type'

	},
	{
		key: 'symptom',
		label: 'Symptom Or Special Need',

	},
	{
		key: 'np',
		label: 'NP'

	},
	{
		key: 'upload',
		label: 'Upload By'

	},
	{
		key: 'status',
		label: 'Status'
	},
	{
		key: 'create_date',
		label: 'Create Date'
	}
];



  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    CustomerService.getAllCustomers().then((data) => {
      setCustomers(data.data);
    })
    ResourceService.getAll().then(data => {
      setResources(data.data);
    }) 
  }, []);

  useEffect(() => {
    if (customers?.length > 0 && resources?.length>0) {
      EventRequestsService.getAllEventRequests({status: 'active'}).then((data) => {
				setEventRequests(data.data);
			})
    }
  }, [resources, customers]);



  useEffect(() => {
    const newEventRequests = [...eventRequests];
    const sortedEventRequests = sorting.key === '' ? newEventRequests : newEventRequests.sort((a, b) => {
      return a[sorting.key].localeCompare(b[sorting.key]);
    });
    setEventRequests(
      sorting.order === 'asc' ? sortedEventRequests : sortedEventRequests.reverse()
    )
  }, [sorting]);


  const redirectToAdmin = () => {
    navigate(`/medical`)
  }


  const goToCreateNew = () => {
    navigate(`/medical/events/request`)
  }

	const deleteItem = (id) => {
		EventRequestsService.updateEventRequest(id, {status: 'inactive'}).then(() => {
			EventRequestsService.getAllEventRequests().then((data) => {
				setEventRequests(data.data);
			})
		})
	}

	const goToNext = (id) => {
    navigate(`/medical/events/edit/${id}`)
  }

	const filterRequests = (request) => {
		if (showDone) {
			return request.status === 'done' || request.status === 'active'
		} else {
			return request.status === 'active'
		}
	}

	const confirmEventRequest = (eventRequest) => {
		EventRequestsService.updateEventRequest(eventRequest.id, {status: 'done', edit_history:[{ employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }]}).then(() => {
			EventRequestsService.getAllEventRequests().then((data) => {
				setEventRequests(data.data);
			})
		})
	}

	const createNewEvent = (eventRequest) => {
		console.log('event', eventRequest);
		const customer = customers.find(c => c.id === eventRequest?.customer_id);
		const resource = resources.find(r => r.id === eventRequest?.resource);
		const newEventData = {
      customer: eventRequest?.customer_id,
      resource: eventRequest?.resource,
      client_name: customer?.name,
      client_pharmacy: customer?.pharmacy,
      client_pcp: customer?.care_provider,
      client_birth_date: customer?.birth_date,
      client_seating: customer?.seating,
      resource_type: resource?.type,
      resource_name: resource?.name,
      resource_phone: resource?.phone,
      resource_contact: resource?.contact,
      resource_address: resource?.address,
      resource_city: resource?.city,
      resource_state: resource?.state,
      // legacy fields end
      // We still wanna keep the legacy fields below
      notes: eventRequest?.symptom,
      other: eventRequest?.symptom,
      disability: customer?.disability? 'yes' : 'no',
    }
    
    const newEvent = {
      data: newEventData,
      // title: title,
      // descrption: description,
      notes: eventRequest?.symptom,
      start_time: new Date(),
      type: 'medical',
      // stop_time: new Date(startTime),
      source_type: resource?.type,
      source_uuid: resource?.id,
      source_name: resource?.name,
      target_type: customer?.type,
      target_uuid: customer?.id,
      target_name: customer?.name,
      create_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
      create_date: new Date(),
      edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
      edit_date: new Date(),
      edit_history:[{ employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }]
    }

    console.log('new Event', newEvent);
		navigate(`/medical/events/create-from-request?event=${encodeURIComponent(JSON.stringify(newEvent))}`)
    // EventsService.createNewEvent(newEvent).then(data => {
		// 	EventRequestsService.updateEventRequest(eventRequest.id, {status: 'done'}).then(() => goToNext(data.data?.id))
		// });
	}


  const getSortingImg = (key) => {
    return sorting.key === key ? (sorting.order === 'asc' ? 'up_arrow' : 'down_arrow') : 'default';
  }

  const sortTableWithField = (key) => {
    let newSorting = {
      key,
      order: 'asc',
    }
    
    if (sorting.key === key && sorting.order === 'asc') {
      newSorting = {...newSorting, order: 'desc'};
    }
    setSorting(newSorting);
  }

	const getStatusLabel = (status) => status === 'active' ? 'In Progress' : (status==='done' ? 'Done' : 'Deleted')
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Medical Appointment Requests List <button className="btn btn-primary btn-sm" onClick={() => {goToCreateNew()}}>Create New Appointment Request</button>  <button className="btn btn-link btn-sm" onClick={() => {redirectToAdmin()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
				<div className="col-md-12 col-sm-12 mb-4">
					Show Completed Requests: <input type="checkbox" value={showDone} checked={showDone === true} onClick={() => setShowDone(!showDone)} />
				
				</div>
        
        <div className="col-md-12">
          <table className="personnel-info-table medical"> 
            <thead>
              <tr>
                {
                  columns.map((column, index) => <th className="sortable-header" key={index}>
                    {column.label}  <span onClick={() => sortTableWithField(column.key)}><img src={`/images/${getSortingImg(column.key)}.png`}></img></span>
                  </th>)
                }
								<th>Completed By</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                eventRequests && (eventRequests.filter((request) => filterRequests(request) ).map(eventRequest => <tr key={eventRequest.id}>
                  <td>{eventRequest?.customer_display}</td>
                  <td>{eventRequest?.resource_display}</td>
                  <td>{EventRequestsService.sourceList.find((item) => item?.value === eventRequest?.source)?.label || eventRequest?.source}</td>
                  <td>{eventRequest?.type}</td> 
                  <td>{eventRequest?.symptom}</td>
                  <td>{eventRequest?.np}</td>
                  <td>{eventRequest?.upload}</td>
									<td>{getStatusLabel(eventRequest?.status)}</td>
                  <td>{new Date(eventRequest?.create_date).toLocaleDateString()}</td>
									<td>{ eventRequest.status === 'done' && eventRequest?.edit_history && eventRequest?.edit_history[eventRequest?.edit_history?.length - 1]?.employee || ''}</td>
                  <td className={eventRequest.status === 'active' ? 'table-button-container': ''}>
										{eventRequest.status !== 'done' && <button className="btn btn-primary btn-sm me-2" onClick={() => createNewEvent(eventRequest)}> New Appointment </button>}
										{eventRequest.status !== 'done' && <button className="btn btn-secondary btn-sm me-2" onClick={() => confirmEventRequest(eventRequest)}> Completed</button>}
              			{/* <button className="btn btn-danger btn-sm" onClick={() => deleteItem(eventRequest?.id)}> Delete </button> */}
									</td>
                </tr>))
              }
            </tbody>
          </table>
           
        </div>
      </div>
    </>
  )
};

export default EventRequestList;