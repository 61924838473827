import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { transRoutesSlice ,selectHistoryInboundRoutes, selectHistoryOutboundRoutes, selectHistoryRoutes, selectAllActiveDrivers, selectAllActiveVehicles } from "./../../store";
import RoutesSection from "./RoutesSection";
import PersonnelSection from "./PersonnelSection";
import DatePicker from "react-datepicker";
import { AuthService } from "../../services";
import {reportRootUrl} from "../../shared";

const RoutesHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inboundRoutes = useSelector(selectHistoryInboundRoutes);
  const outboundRoutes = useSelector(selectHistoryOutboundRoutes);
  const allRoutes = useSelector(selectHistoryRoutes);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  const backToDashboard = () => {
    navigate('/trans-routes/dashboard');
  }
  const { fetchAllHistoryRoutes } = transRoutesSlice.actions;
  const getDateString = (date) => {
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
  }
  const searchForHistory = () => {
    if (selectedDate) {
      dispatch(fetchAllHistoryRoutes({dateText: getDateString(selectedDate)}));
    }
  };
  const generateRouteReport = () => {
    window.open(`${reportRootUrl}?token=${localStorage.getItem('token')}&date=${getDateString(selectedDate)}`, '_blank');
  }

  return (
    <>
      <div className="list row">
        <div className="col-md-4 text-primary mb-2">
          <h5>Route History <button className="btn btn-link btn-sm ms-2 me-2" onClick={() => backToDashboard()}>Back To Dashboard</button> <button type="button" className="btn btn-primary btn-sm me-2" onClick={()=> generateRouteReport()}>Generate Route Report</button></h5>
        </div>
      </div>
      <div className="list row mb-4">
        <div className="mb-2 ms-2">
          <span>Select A Date: </span>
        </div>
        <div className="col-md-4 mb-2">
          <DatePicker selected={selectedDate} onChange={(v) => setSelectedDate(v)} />
        </div>
        <div className="col-md-4 text-primary mb-2">
          <button className="btn btn-primary btn-sm" onClick={() => searchForHistory()}>Search</button>
        </div>
        {selectedDate && <div className="col-md-4 text-primary mb-2">
          Selected Date: {getDateString(new Date(selectedDate))}
        </div>}
      </div>
      <div className="list row">
        <div className="col-md-12 mb-4">
          <RoutesSection transRoutes={inboundRoutes} drivers={drivers} vehicles={vehicles} sectionName="Inbound Routes"/>
        </div>
        <hr />
        <div className="col-md-12 mb-4">
          <RoutesSection transRoutes={outboundRoutes} drivers={drivers} vehicles={vehicles} sectionName="Outbound Routes"/>
        </div>
        <hr />
      </div>
      <div className="list row">
        <div className="col-md-12 mb-4">
          <PersonnelSection transRoutes={allRoutes} showCompletedInfo={false} showGroupInfo={false} allowForceEdit={AuthService.canViewRoutes()} showFilter={true} sectionName="Personnel Status (click on each user to edit)"/>
        </div>
      </div>
    </>
    
  );
};

export default RoutesHistory;