import React, {useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllRouteTemplates, selectAllActiveDrivers, selectAllActiveVehicles } from "./../../store";

const RouteTemplatesList = () => {
  const params = new URLSearchParams(window.location.search);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  const allTemplates = useSelector(selectAllRouteTemplates);
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState('');

  const redirectToCreateRoute = () => {
    navigate(`/trans-routes/create${params.get('type')?`?type=${params.get('type')}`: ''}${params.get('date')? `&date=${params.get('date')}` : ''}`);
  }

  const goToEdit = (id) => {
    navigate(`/trans-routes/templates/edit/${id}${params.get('type')?`?type=${params.get('type')}`: ''}${params.get('date')? `&date=${params.get('date')}` : ''}`);
  }

  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Manage Route Templates <button className="btn btn-link btn-sm" onClick={() => {redirectToCreateRoute()}}>Back To Create Route</button></h5> 
        </div>
      </div>
      
      <div className="list row">
				<div className="col-md-12">
					<div className="mb-4">
						Type In Template Name To Filter: <input type="text" value={nameFilter} onChange={(e) => setNameFilter(e.currentTarget.value)}/>
					</div>
					<table className="personnel-info-table">
            <thead>
              <tr>
                <th>Template Name</th>
                <th>Type</th>
                <th>Driver</th>
                <th>Vehicle</th>
                <th>Status</th>
								<th>Action</th>
              </tr>
            </thead>
            <tbody>
							{
								allTemplates.filter(
									(item) => item.name.includes(nameFilter)).map(({id, name, type, vehicle, driver, status}, index) => {
									return (<tr key={index}>
										<td>{name}</td>
										<td>{type}</td>
										<td>{drivers.find((d) => d.id === driver)?.name}</td>
										<td>{vehicles.find((v) => v.id === vehicle)?.vehicle_number}</td>
										<td>{status}</td>
										<td><button className="btn btn-primary btn-sm" onClick={() => goToEdit(id)}>Edit</button></td>
									</tr>)
								})
							}
						</tbody>
					</table>
				</div>
			</div>
    </>
    
  );
};

export default RouteTemplatesList;