import React from "react";
import PersonnelInfoTable from "./PersonnelInfoTable";

const PersonnelSection = ({transRoutes, sectionName, showCompletedInfo, showGroupInfo, allowForceEdit, showFilter, driverName, vehicle, relatedOutbound, vehicles}) => {
  return (
    <>
      <div className="text-primary mb-4"><h5>{sectionName}</h5></div>
      <div className="list row">
        <div className="col-md-12">
         <PersonnelInfoTable transRoutes={transRoutes} relatedOutbound={relatedOutbound} showCompletedInfo={showCompletedInfo} allowForceEdit={allowForceEdit} showFilter={showFilter} showGroupInfo={showGroupInfo} driverName={driverName} vehicle={vehicle} vehicles={vehicles}/>
        </div>
      </div>
    </>
    
  );
};

export default PersonnelSection;