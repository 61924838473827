import http from "../http-common";
const getAllActiveCustomers = () => {
  return http.get('/customers/active');
};

const getAllCustomers = () => {
  return http.get('/customers');
};

const createNewCustomer = (data) => {
  data.status = 'active';
	return http.post('/customers', data);
};

const createNewClient = (data) => {
  data.status = 'active';
  return http.post('/clients', data);
}

const updateCustomer = (id, data) => {
  return http.put(`/customers/${id}`, data);
}

const updateClient = (id, data) => {
  data.status = 'active';
  return http.put(`/clients/${id}`, data);
}

const deleteCustomer = (id, data) => {
  data.status = 'inactive';
  return http.put(`/customers/${id}`, data);
}

const deleteClient = (id, data) => {
  data.status = 'inactive';
  return http.put(`/clients/${id}`, data);
}

const uploadAvatar = (filename, data) => {
  return http.post(`/files/upload/${filename}`, data)
}

const getAvatar = (filename) => {
  return http.get(`/files/${filename}`);
}

const getCustomersByNameOrEmail = (nameOrEmail) => {
  return http.get(`/customers/search`, {params: {nameOrEmail}});
}

const getClientsByNameOrEmail = (nameOrEmail) => {
  return http.get(`/clients/search`, {params: {nameOrEmail}});
}

const getCustomer = (id) => {
  return http.get(`customers/${id}`);
}

const getClient = (id) => {
  return http.get(`clients/${id}`);
}


export const CustomerService = {
  getAllActiveCustomers,
  uploadAvatar,
  getAvatar,
  createNewCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomersByNameOrEmail,
  getCustomer,
  getAllCustomers,
  createNewClient,
  updateClient,
  deleteClient,
  getClientsByNameOrEmail,
  getClient
};
