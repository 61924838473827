import React from "react";
import { useNavigate} from "react-router-dom";
import { ROUTE_STATUS, PICKUP_STATUS } from "./../../shared";

const RouteCard = ({transRoute, drivers, vehicles, driver, vehicle}) => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const scheduleDate = params.get('dateSchedule');
  const handleOnClick = () => {
    if (scheduleDate) {
      navigate(`/trans-routes/${transRoute.id}?dateSchedule=${scheduleDate}`);
    } else {
      navigate(`/trans-routes/${transRoute.id}`);
    }
   
  }
  const goToEditDriver = (id) => {
    navigate(`/employees/edit/${id}?redirect=schedule&type=driver`);
  }
  const goToEditVehicle = (id) => {
    navigate(`/vehicles/edit/${id}?redirect=schedule`);
  }
  const getRouteStatus = (route) => {
    let routeStatusData = {};
    const hasAbsentScheduled = route?.route_customer_list?.find((customer) => customer.customer_pickup_status === PICKUP_STATUS.SCHEDULE_ABSENT);
    const unexpectedAbsent = route?.status?.includes(ROUTE_STATUS.UNEXPECTED_ABSENT);
    if (route?.status?.includes(ROUTE_STATUS.ENROUTE)) {
      routeStatusData = {
        text: 'Enroute',
        className: 'yellow'
      }
    }
    if (route?.status?.includes(ROUTE_STATUS.ENROUTE_TO_CENTER)) {
      routeStatusData = {
        text: 'Picked up all participants, enroute to center',
        className: 'blue'
      }
      if (hasAbsentScheduled || unexpectedAbsent) {
        routeStatusData = {
          text: 'Has absents, enroute to center',
          className: 'blue'
        }
      }
    }
    if (route?.status?.includes(ROUTE_STATUS.DROPPED_OFF_ALL)) {
      routeStatusData = {
        text: 'Dropped off all participants',
        className: 'blue'
      }
    }
    if (route?.status?.includes(ROUTE_STATUS.SIGN_OFF)) {
      routeStatusData = {
        text: route.type === 'inbound' ? 'Backed to center' : 'Task finished, vehicle parked',
        className: 'green'
      }
    }
    if (!route?.status || route?.status?.length === 0 || route?.status?.includes(ROUTE_STATUS.NOT_STARTED)) {
      routeStatusData = {
        text: 'Not Started',
        className: 'gray'
      }
    }
    const now = new Date();
    const dateString = ((now.getMonth() > 8) ? (now.getMonth() + 1) : ('0' + (now.getMonth() + 1))) + '/' + ((now.getDate() > 9) ? now.getDate() : ('0' + now.getDate())) + '/' + now.getFullYear();
    // if (!route?.status?.includes(ROUTE_STATUS.SIGN_OFF) && now.getHours() >= 16 &&  dateString === route?.schedule_date) {
    if (!route?.status?.includes(ROUTE_STATUS.SIGN_OFF) &&  Date.parse(dateString) > Date.parse(route?.schedule_date)) {
      routeStatusData = {
        text: 'After 4pm, not sign off',
        className: 'purple'
      }
    }
    return routeStatusData;
  }
  
  const routeStatus = getRouteStatus(transRoute);

  return (
    <>
      {
        transRoute && (<div onClick={handleOnClick} className={`card-container ${routeStatus.className==='purple' ? routeStatus.className : ''}`}>
          <div className="">{transRoute.name}</div>
          <div>{drivers.find((driver) => driver.id === transRoute.driver)?.name}</div>
          <div>{vehicles.find((vehicle) => vehicle.id === transRoute.vehicle)?.tag}</div>
          <div className="list row">
            <div className="col-md-12 card-status">
              <div className="float-end">{routeStatus?.text}</div>
              <div className={`${routeStatus.className==='purple' ? '' : routeStatus.className} float-end`} />
            </div>
          </div>
        </div>)
      }
      {
        !transRoute && driver && (<div className={`card-container clickable`} onClick={() => goToEditDriver(driver.id)}>
          <div className="">{driver.name}</div>
          <div className="list row">
            <div className="col-md-12 card-status">
              <div className="float-end">{`Capacity Limit: ${driver.driver_capacity} seats`}</div>
            </div>
          </div>
        </div>)
      }
      {
        !transRoute && vehicle && (<div className={`card-container clickable`} onClick={() => goToEditVehicle(vehicle.id)}>
          <div className="">{vehicle.vehicle_number}</div>
          <div><small>Tag: {vehicle.tag}</small></div>
          <div><small>{`Capacity Limit: ${vehicle.capacity} seats`}</small></div>
          <div><small>EzPass: {vehicle.ezpass}</small></div>
          <div><small>GPS Tag: {vehicle.gps_tag}</small></div>
        </div>)
      }
    </>
    
    
    
  );
};

export default RouteCard;