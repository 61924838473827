import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "./../store"

const UsersList = () => {
  const [currentIndex, setCurrentIndex] = useState(-1);

  const users = useSelector(state => state.users.users);
  const dispatch = useDispatch();
  const { fetchAllUsers } = userSlice.actions;

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [])

  return (
    <div className="list row">
      <div className="col-md-6">
        <h4>Users List</h4>

        <ul className="list-group">
          {users &&
            users.map((user, index) => (
              <li
                className={
                  "list-group-item " + (index === currentIndex ? "active" : "")
                }
                key={index}
              >
                {user.username}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default UsersList;