import { createSlice, CaseReducer } from "@reduxjs/toolkit";

const initialState = {
  employees: [],
  loading: false,
  error: null
};

const createEmployee = (state, action) => {}
const createEmployeeFailure = (state, action) => {
  state.error = action.payload;
}

const updateEmployee = (state, action) => {};
const updateEmployeeFailure = (state, action) => {
  state.error = action.payload;
};

const deleteEmployee = (state, action) => {};
const deleteEmployeeFailure = (state, action) => {
  state.error = action.payload;
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    createEmployee,
    createEmployeeFailure,
    updateEmployee,
    updateEmployeeFailure,
    deleteEmployee,
    deleteEmployeeFailure
  }
});

