import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { customerSlice } from "./../../store";
import { AuthService, CustomerService } from "../../services";
import { CUSTOMER_TYPE } from "../../shared";

const CustomersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    if (!AuthService.canViewCustomers()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    CustomerService.getAllCustomers().then((data) => {
      setCustomers(data.data.sort((a, b) => a.lastname > b.lastname ? 1: -1));
    })
  }, []);

  const redirectToAdmin = () => {
    navigate(`/admin/customer-report`)
  }

  const goToEdit = (id) => {
    navigate(`/customers/edit/${id}`)
  }

  const goToCreateNew = () => {
    navigate(`/customers`)
  }


  const goToView = (id) => {
    navigate(`/customers/${id}`)
  }

  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>All Customers <button className="btn btn-primary btn-sm" onClick={() => {goToCreateNew()}}>Create New Customer</button> <button className="btn btn-link btn-sm" onClick={() => {redirectToAdmin()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12 mb-4">
          Filter By Name: <input className="me-2" type="text" value={keyword}  onChange={(e) => setKeyword(e.currentTarget.value)} />
        </div>
        <div className="col-md-12 mb-4">
          <input className="me-2" type="checkbox" value={showInactive} checked={showInactive === true} onChange={() => setShowInactive(!showInactive)} />
            Show Transferred / Deactivated Customers
        </div>
        <div className="col-md-12">
          <table className="personnel-info-table"> 
            <thead>
              <tr>
                <th>Name</th>
                <th>Chinese Name</th>
                <th>Email</th>
                <th>Type</th>
                <th>Pickup Status</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                customers && customers.filter((item) => item?.name.toLowerCase().includes(keyword.toLowerCase())).filter(item => showInactive ? item : item.type !== CUSTOMER_TYPE.TRANSFERRED && item.type!=CUSTOMER_TYPE.DECEASED && item.type!=CUSTOMER_TYPE.DISCHARED && item.status === 'active').map(customer => <tr key={customer.id}>
                  <td>{customer?.name}</td>
                  <td>{customer?.name_cn}</td>
                  <td>{customer?.email}</td>
                  <td>{customer?.type}</td>
                  <td>{customer?.pickup_status}</td>
                  <td>
                    {AuthService.canAddOrEditCustomers() && <button className="btn btn-primary btn-sm me-2" onClick={() => goToEdit(customer?.id)}>Edit</button> }
                    {AuthService.canViewCustomers() && <button className="btn btn-default btn-sm me-2" onClick={() => goToView(customer?.id)}>View</button>}
                  </td>
                </tr>)
              }
            </tbody>
          </table>
           
        </div>
      </div>
    </>
  )
};

export default CustomersList;