import { createSlice, CaseReducer } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  loading: false,
  error: null
};


const fetchAllUsers = (state, action) => {
  state.loading = true;
}
const fetchAllUsersSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.users = action.payload;
}
const fetchAllUsersFailure = (state, action) => {
  state.users = [];
  state.error = action.payload;
}


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchAllUsers,
    fetchAllUsersSuccess,
    fetchAllUsersFailure
  }
});

