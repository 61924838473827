import { takeEvery, all, call, put } from 'redux-saga/effects';
import { employeeSlice } from './../store';
import { EmployeeService, DriverService } from './../services';

const { createEmployee, createEmployeeFailure, updateEmployee, updateEmployeeFailure, deleteEmployee, deleteEmployeeFailure } = employeeSlice.actions;



function* createEmployeeSaga(action) {
    try {
      yield call(EmployeeService.createNewEmployee, action.payload.data);
      if (action.payload.data.roles.includes('driver')) {
        yield call(DriverService.createNewDriverAsStaff, action.payload.data)
      }
    } catch(ex) {
      yield put(createEmployeeFailure(ex));
    }
  }

function* updateEmployeeSaga(action) {
  try {
    yield call(EmployeeService.updateEmployee, action.payload.id, action.payload.data);
    if (action.payload?.currentEmployee.roles.includes('driver')) {
      const existedStaffs = (yield DriverService.getStaffsByNameOrEmail(action.payload.currentEmployee.email)).data;
      if (existedStaffs && existedStaffs.length > 0) {
        const staffData = Object.assign({}, action.payload.data );
        delete staffData.address; 
        yield call(DriverService.updateDriverInStaff(existedStaffs[0].id, staffData))
      }
    }
    
  } catch(ex) {
    yield put(updateEmployeeFailure(ex));
  }
}

function* deleteEmployeeSaga(action) {
  try {
    yield call(EmployeeService.deleteEmployee, action.payload.id, action.payload.data);
  } catch(ex) {
    yield put(updateEmployeeFailure(ex));
  }
}

export function* employeeEffect() {
  yield all([
    yield takeEvery(createEmployee.type, createEmployeeSaga),
    yield takeEvery(updateEmployee.type, updateEmployeeSaga),
    yield takeEvery(deleteEmployee.type, deleteEmployeeSaga)
  ])
};