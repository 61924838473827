import React, {useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService, ResourceService } from "../../services";

const UpdateResource = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [currentResource, setCurrentResource] =  useState(undefined);
  const [name, setName] = useState('');
  const [originalName, setOriginalName] = useState('');
	const [specialty, setSpecialty] = useState('');
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [contact, setContact] = useState('');
  const [phone, setPhone] = useState('');
  const [dataObject, setDataObject] = useState(undefined);
  const [note, setNote] = useState('');
  const [fax, setFax] = useState('');
  const [status, setStatus] = useState('');
  const [branchName, setBranchName] = useState('');
  
 
  const params = new URLSearchParams(window.location.search);

  const redirectTo = (id) => {
    navigate(`/medical/resources/list`);
  }

  const redirectToView = () => {
		navigate(`/medical/resources/${urlParams.id}`);
  }

  const saveResource = () => {
    const newResource = {
      ...currentResource,
      data: JSON.parse(dataObject),
      name,
      description,
      note,
      name_original: originalName,
      name_branch: branchName,
      specialty,
      type,
      category,
      color,
      address,
      state,
      city,
      zipcode,
      contact,
      phone,
      fax,
      status,
      edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
      edit_date: new Date(),
      edit_history: currentResource?.edit_history? [...currentResource.edit_history, { employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }] : [{ employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }]
    }

    console.log('new Resource', newResource);
    
    ResourceService.updateResource(urlParams.id, newResource).then(data => redirectToView());
  };
  

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    ResourceService.getResource(urlParams.id).then(resourceData => {
      setCurrentResource(resourceData.data);
    })
  }, []);

  useEffect(() => {
    if (currentResource) {
      setName(currentResource?.name);
      setOriginalName(currentResource?.name_original);
      setBranchName(currentResource?.name_branch);
      setSpecialty(currentResource?.specialty);
      setType(currentResource?.type);
      setCategory(currentResource?.category);
      setDescription(currentResource?.description);
      setColor(currentResource?.color);
      setAddress(currentResource?.address);
      setState(currentResource?.state);
      setCity(currentResource?.city);
      setZipcode(currentResource?.zipcode);
      setContact(currentResource?.contact);
      setPhone(currentResource?.phone);
      setFax(currentResource?.fax);
      setNote(currentResource?.note);
      setStatus(currentResource?.status);
      setDataObject(JSON.stringify(currentResource?.data || {}));
    }
    
  }, [currentResource]);

  return (
    <>
      <div className="list row">
        <div className="col-md-12 text-primary">
          <h5>Update Resource <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-4 mb-4">
          <div>Doctor Name:</div> <input type="text" value={name || ''} onChange={e => setName(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Office Name:</div> <input type="text" value={originalName || ''} onChange={e => setOriginalName(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Branch Name:</div> <input type="text" value={branchName || ''} onChange={e => setBranchName(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          {/* <div>Specialty:</div> <input type="text" value={specialty || ''} onChange={e => setSpecialty(e.target.value)}/> */}
          <div>Specialty:</div>
          <select value={specialty} onChange={e => setSpecialty(e.target.value)}>
            <option value=""></option>
            {
              ResourceService.resourceOptionList.map((item) => <option value={item}>{item}</option>)
            }
          </select>
        </div>
        
        <div className="col-md-4 mb-4">
          <div>Type:</div>
          <select value={type} onChange={e => setType(e.target.value)}>
            <option value="doctor">Doctor</option>
            <option value="pharmacy">Pharmacy</option>
            <option value="hospital">Hospital</option>
            <option value="surgical center">Surgical Center</option>
            <option value="government agency">Government Agency</option>
            <option value="other">Other</option>
          </select>
        </div>
        {/* <div className="col-md-4 mb-4">
          <div>Category:</div> <input type="text" value={category || ''} onChange={e => setCategory(e.target.value)}/>
        </div> */}
        <div className="col-md-4 mb-4">
          <div>Description:</div> <input type="text" value={description || ''} onChange={e => setDescription(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Color:</div> <input type="text" value={color || ''} onChange={e => setColor(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Address:</div> <textarea value={address || ''} onChange={e => setAddress(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>City:</div> <input type="text" value={city || ''} onChange={e => setCity(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>State:</div> <input type="text" value={state || ''} onChange={e => setState(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Zipcode:</div> <input type="text" value={zipcode || ''} onChange={e => setZipcode(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Contact:</div> <textarea value={contact || ''} onChange={e => setContact(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Phone:</div> <input type="text" value={phone || ''} onChange={e => setPhone(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Fax:</div> <input type="text" value={fax || ''} onChange={e => setFax(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Note:</div> <textarea value={note || ''} onChange={e => setNote(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Data (object/json):</div> <textarea value={dataObject || ''} onChange={e => setDataObject(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Status:</div>
          <select value={status} onChange={e => setStatus(e.target.value)}>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>
      <div className="list row mb-5">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <button className="btn btn-primary btn-sm me-2 mb-2" onClick={() => saveResource()}> Save </button>
          <button className="btn btn-default btn-sm" onClick={() => redirectTo()}> Cancel </button>
        </div>
      </div>
        
    </>
  );
};

export default UpdateResource;