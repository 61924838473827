export const ROUTE_STATUS = {
  	NOT_STARTED: 'notStarted',
	ENROUTE: 'enroute',
	ENROUTE_TO_CENTER: 'enrouteToCenter',
	SIGN_OFF: 'signedOff',
	DROPPED_OFF_ALL: 'droppedOffAll',
	UNEXPECTED_ABSENT: 'unexpectedAbsent',
};

export const PERSONAL_ROUTE_STATUS = {
	DISABLED: 'disabled',
	PICKED: 'picked',
	IN_CENTER: 'inCenter',
	LEFT_CENTER: 'leftCenter',
	DROPPED_OFF: 'droppedOff',
	UNEXPECTED_ABSENT: 'unexpectedAbsent',
	SCHEDULED_ABSENT: 'scheduledAbsent',
	NO_STATUS: 'noStatus',
	SKIP_DROPOFF: 'skipDropOff',
	CALLING: 'calling'
};

export const PERSONAL_ROUTE_STATUS_TEXT = {
	'disabled': {
		text: 'Disabled',
		className: 'dark-gray'
	},
	'picked': {
		text: 'Picked',
		className: 'light-green'
	},
	'inCenter': {
		text: 'In Center',
		className: 'dark-green'
	},
	'leftCenter': {
		text: 'Left Center',
		className: 'dark-blue'
	},
	'droppedOff': {
		text: 'Dropped Off',
		className: 'black'
	},
	'unexpectedAbsent': {
		text: 'Unexpected Absent',
		className: 'red'
	},
	'noStatus': {
		text: 'No Status',
		className: 'white'
	},
	'scheduledAbsent': {
		text: 'Scheduled Absent',
		className: 'dark-red'
	},
	'skipDropOff': {
		text: 'Skip Drop Off',
		className: 'light-red'
	},
	'calling': {
		text: "Calling",
		className: 'yellow'
	}
}

export const reportRootUrl = (window.location.hostname.includes('worldshine2.mayo.llc') || window.location.hostname.includes('site2') || window.location.hostname.includes('localhost')) ? 'https://wsutil2.mayo.llc/reports.php' : (window.location.hostname.includes('site3') || window.location.hostname.includes('worldshine3.mayo.llc')) ? 'https://wsutil3.mayo.llc/reports.php' : 'https://wsutil.mayo.llc/reports.php';