import http from "../http-common";
const getAll = () => {
  return http.get("/vehicles");
};
const getAllActiveVehicles = () => {
  return http.get("/vehicles/active")
};
const updateVehicles = (id, data) => {
  return http.put(`/vehicles/${id}`, data)
};
const createNewVehicles = (data) => {
	return http.post('/vehicles', data);
};
const deleteVehicles = (id, data) => {
  return http.delete(`/vehicles/${id}`);
};
const getVehicle = (id) => {
  return http.get(`/vehicles/${id}`);
};

export const VehicleService = {
  getAll,
  getAllActiveVehicles,
  updateVehicles,
  createNewVehicles,
  deleteVehicles,
  getVehicle
};
