import http from "../http-common";
const getAll = (type) => {
  const params = {};
  if (type) {
    params.type = type;
  }
  return http.get("/resources", {params});
};

const createNewResource = (data) => {
  data.status = 'active';
	return http.post('/resources', data);
};


const updateResource = (id, data) => {
  return http.put(`/resources/${id}`, data);
}

const disableResource = (id, data) => {
  return http.put(`/resources/${id}/disable`, data);
}

const getResource = (id) => {
  return http.get(`/resources/${id}`);
}

const deleteResource = (id) => {
  return http.delete(`/resources/${id}`)
}

const resourceOptionList = [
  'Family Medicine (PCP)',
  'Acupuncture',
  'Allergy & Asthma',
  'Audiology',
  'Botox Therapy',
  'Breast Surgery',
  'Cardiology',
  'Cardiovascular ',
  'Colon & Rectal Surgery',
  'Dentist',
  'Dermatology',
  'Dialysis',
  'Endocrinology & Diabetes',
  'Endodontist',
  'Endoscopy Center',
  'Otolaryngology (ENT)',
  'Eye Surgery Center',
  'Gastroenterology',
  'General Surgery',
  'GYN Oncology',
  'Head & Neck Surgery',
  'Health Boutique',
  'Hearing Aids',
  'Hematology & Oncology', 
  'Hepatology', 
  'Hospital',
  'Infectious disease',
  'Medical Center',
  'Lab',
  'Modified Barium Swallow (MBS) Study ',
  'Medical Supply',
  'Nephrology',
  'Neuro Surgeon',
  'Neurology',
  'OB/GYN',
  'Optometry (OD)',
  'Oncology',
  'Oncology Center',
  'Ophthalmology',
  'Ophthalmology (Retina Specialist)',
  'Oral Surgery',
  'Orthopaedic',
  'Osteopath',
  'Pain Management',
  'Periodontist',
  'Pharmacy',
  'Physical Therapy',
  'Physical, Occupational, & Speech Therapy',
  'Podiatry',
  'Psychiatry',
  'Pulmonology',
  'Radiation Oncology',
  'Radiology',
  'Rehabilitation',
  'Rheumatology',
  'Sleep Medicine',
  'Substance Abuse Treatment',
  'Sports Medicine',
  'Surgery',
  'Surgery Center',
  'Thoracic Surgery',
  'Traditional Chinese Medicine',
  'Urgent Care',
  'Urogynecology', 
  'Urology',
  'Vascular and Vein',
  'Vascular & Interventional Radiologist',
  'Weight Loss / GYM',
  'Wound Clinic'
];

export const ResourceService = {
  getAll,
  createNewResource,
  updateResource,
  disableResource,
  getResource,
  deleteResource,
  resourceOptionList
};
