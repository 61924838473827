import React, {useState, useEffect} from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { customerSlice } from "./../../store";
import { AuthService, CustomerService, EventsService, ResourceService } from "../../services";

const ViewEvent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [currentEvent, setCurrentEvent] =  useState(undefined);
  const [resources, setResources] = useState([]);
  const [customers, setCustomers] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const redirectToCalendar = () => {
		navigate(`/medical/events/calendar`);
  }

  const redirectTo = (id) => {
    navigate(`/medical/events/list`);
  }

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    Promise.all([ResourceService.getAll(), CustomerService.getAllCustomers()]).then(([resourcesData, customersData]) => {
      setResources(resourcesData.data);
      setCustomers(customersData.data);
      if (!currentEvent) {
        EventsService.getEvent(urlParams.id).then(eventData => {
          setCurrentEvent(eventData.data);
        })
        
      }
    })
  }, []);

  return (
    <>
      <div className="list row">
        <div className="col-md-12 col-xs-12">
          <div className="list row mb-4">
            <div className="col-md-12 text-primary">
              <h5>View Medical Event Details<button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
            </div>
          </div>
          <div className="list row mb-4">
            <div className="col-md-6 mb-4">
            <div>Resource:</div>
              { currentEvent?.data?.resource ? (resources.find(r => r.id === currentEvent?.data?.resource)?.name || currentEvent?.data?.resource_name) : currentEvent?.data?.resource_name}
            </div>
            <div className="col-md-6 mb-4">
              <div>Client:</div>
              { currentEvent?.data?.customer ? (customers.find(r => r.id === currentEvent?.data?.customer)?.name || currentEvent?.data?.client_name) : currentEvent?.data?.client_name}
            </div>
            <div className="col-md-6 mb-4">
              <div>Title:</div> {currentEvent?.title}
            </div>
            <div className="col-md-6 mb-4">
              <div>Start Time:</div> {`${new Date(currentEvent?.start_time)?.toLocaleDateString()} ${new Date(currentEvent?.start_time)?.toLocaleTimeString()}`}
            </div>
            {/* <div className="col-md-12 mb-4">
              <div>Description:</div> {currentEvent?.description}
            </div> */}
            <div className="col-md-4 mb-4">
              <div>Color:</div>
              {currentEvent?.color || ''}
            </div>
            <div className="col-md-4 mb-4">
              <div>New Patient:</div>
              {currentEvent?.data?.new_patient}
            </div>
            <div className="col-md-4 mb-4">
              <div>Confirmed:</div> 
              {currentEvent?.data?.confirmed}
            </div>
            <div className="col-md-4 mb-4">
              <div>Fasting:</div>
              {currentEvent?.data?.fasting}
            </div>
            <div className="col-md-4 mb-4">
              <div>Interpreter Level:</div>
              {currentEvent?.data?.interpreter}
            </div>
            <div className="col-md-4 mb-4">
              <div>Doctor Order:</div> 
              {currentEvent?.data?.doc_order}
            </div>
            <div className="col-md-4 mb-4">
              <div>Disability: </div>
              {currentEvent?.data?.disability}
            </div>
            <div className="col-md-4 mb-4">
              <div>Need Id: </div>
              {currentEvent?.data?.need_id}
            </div>
            <div className="col-md-4 mb-4">
              <div>Need Medication List</div>
              {currentEvent?.data?.need_med_list}
            </div>
            <div className="col-md-4 mb-4">
              <div>Disability Support:</div>
              {currentEvent?.data?.disability_support}
            </div>
            <div className="col-md-4 mb-4">
              <div>Transportation:</div>
              {currentEvent?.data?.trans_method}
            </div>
            <div className="col-md-4 mb-4">
              <div>Notes:</div>
              {currentEvent?.data?.notes}
            </div>
            <div className="col-md-4 mb-4">
              <div>Reason:</div> 
              {currentEvent?.data?.reason}
            </div>
            <div className="col-md-4 mb-4">
              <div>Other Requirements:</div>
              {currentEvent?.data?.other}
            </div>
          </div>
         
        </div>
      </div>
        
    </>
  );
};

export default ViewEvent;