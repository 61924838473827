import http from "../http-common";
const getAll = () => {
  return http.get('/route-templates');
};

const updateRouteTemplate = (id, data) => {
	return http.put(`/route-templates/${id}`, data);
};

const createNewRouteTemplate = (data) => {
	return http.post('/route-templates', data);
};

const deleteRouteTemplate = (id) => {
	return http.delete(`/route-templates/${id}`);
}

export const RouteTemplateService = {
  getAll,
	updateRouteTemplate,
	createNewRouteTemplate,
	deleteRouteTemplate
};
