import React, {useEffect, useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { transRouteTemplatesSlice, selectAllRouteTemplates, selectAllActiveDrivers, selectAllActiveVehicles } from "./../../store";
import RouteCustomerEditor from "./RouteCustomerEditor";

const RouteTemplateEdit = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = useParams();
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  const allTemplates = useSelector(selectAllRouteTemplates);
  const currentTemplate = (allTemplates.find(item => item.id === params.id)) || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateRouteTemplate } = transRouteTemplatesSlice.actions;
  const [routeName, setRouteName] = useState('');
  const [newDriver, setNewDriver] = useState('');
  const [newVehicle, setNewVehicle] = useState('');
  const [newRouteType, setNewRouteType] = useState('');
  const [newCustomerList, setNewCustomerList] = useState([]);

  const redirectToTemplatesList = () => {
    navigate(`/trans-routes/templates${urlParams.get('type')?`?type=${urlParams.get('type')}`: ''}${urlParams.get('date')? `&date=${urlParams.get('date')}` : ''}`);
  }

  const softDeleteCurrentTemplate = () => {
    const data = Object.assign({}, currentTemplate, {status: 'disabled'})
    dispatch(updateRouteTemplate({ id: currentTemplate.id, data }));
    redirectToTemplatesList();
  }

  const updateCurrentTemplate = () => {
    const data = Object.assign({}, currentTemplate, {name: routeName, driver: newDriver, vehicle: newVehicle, type: newRouteType, route_customer_list: newCustomerList});
    let payload = { id: currentTemplate.id, data };
    dispatch(updateRouteTemplate(payload));
    redirectToTemplatesList();
  }

  useEffect(() => {
    if (currentTemplate) {
      setRouteName(currentTemplate.name);
      setNewDriver(currentTemplate.driver);
      setNewVehicle(currentTemplate.vehicle);
      setNewRouteType(currentTemplate.type);
      setNewCustomerList(currentTemplate.route_customer_list);
    }
  }, [currentTemplate])

  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>{currentTemplate?.name} <button className="btn btn-link btn-sm" onClick={() => {redirectToTemplatesList()}}>Back To List</button></h5> 
        </div>
      </div>
      <div className="list row mb-5">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <button className="btn btn-primary btn-sm me-4" onClick={() => updateCurrentTemplate()}> Save Template </button>
          <button className="btn btn-default btn-sm" onClick={() => redirectToTemplatesList()}> Cancel </button>
          <button className="btn btn-danger btn-sm float-end" onClick={() => softDeleteCurrentTemplate()}>Delete Template</button>
        </div>
        
      </div>
      <div className="list row mb-4">
        <div className="col-md-6 mb-4">
          Name: <input type="text" value={routeName || ''} onChange={e => setRouteName(e.target.value)}/>
        </div>
        <div className="col-md-6 mb-4">Vehicle: <select value={newVehicle} onChange={e => setNewVehicle(e.target.value)}>
           {vehicles.map((vehicle) => (<option key={vehicle.id} value={vehicle.id}>{vehicle.vehicle_number}</option>))}
          </select>
        </div>
        <div className="col-md-6 mb-4">Driver: <select value={newDriver} onChange={e => setNewDriver(e.target.value)}>
            {drivers.map((driver) => <option key={driver.id} value={driver.id}>{driver.name}</option>)}
          </select>
        </div>
        <div className="col-md-6 mb-4">Type: <select value={newRouteType} onChange={e => setNewRouteType(e.target.value)}>
            <option value="inbound">Inbound</option>
            <option value="outbound">Outbound</option>
          </select>
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12 mb-4">
          <RouteCustomerEditor currentRoute={currentTemplate} setNewCustomerList={setNewCustomerList}></RouteCustomerEditor>
        </div>
      </div>
      { newVehicle && newVehicle !== '' && (<div className="list row mb-4">
          <div className="col-md-12 create-route-container">
            <h6>Vehicle Info</h6>
            <div className="list row">
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Vehicle Number: {vehicles.find(item => item.id === newVehicle)?.vehicle_number}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Tag: {vehicles.find(item => item.id === newVehicle)?.tag}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">EzPass: {vehicles.find(item => item.id === newVehicle)?.ezpass}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">GPS: {vehicles.find(item => item.id === newVehicle)?.gps_tag}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Capacity: {vehicles.find(item => item.id === newVehicle)?.capacity}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Status: {vehicles.find(item => item.id === newVehicle)?.status}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Mileage: {vehicles.find(item => item.id === newVehicle)?.mileage}</div>
            </div>
          </div>
      </div>)}
      { newDriver && newDriver !== '' && (<div className="list row mb-4">
          <div className="col-md-12 create-route-container">
            <h6>Driver Info</h6>
            <div className="list row">
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Name: {drivers.find(item => item.id === newDriver)?.name}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Chinese Name: {drivers.find(item => item.id === newDriver)?.name_cn}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Driver Capacity: {drivers.find(item => item.id === newDriver)?.driver_capacity}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Roles: {drivers.find(item => item.id === newDriver)?.roles}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Phone: {drivers.find(item => item.id === newDriver)?.phone}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Email: {drivers.find(item => item.id === newDriver)?.email}</div>
              <div className="col-md-4 col-sm-6 col-xs-12 mb-2">Employment Status: {drivers.find(item => item.id === newDriver)?.employment_status}</div>
            </div>
          </div>
      </div>)}
    </>
    
  );
};

export default RouteTemplateEdit;