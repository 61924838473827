import http from "../http-common";
import { EMPLOYEE_TITLE, EMPLOYEE_TITLE_CN, EMPLOYEE_TITLE_ROLES_MAP } from "../shared";
const getAllActiveDrivers = (role, status) => {
  const params = {};
  if (role) {
    params.role = role.toLowerCase();
  }
  if (status) {
    params.status = status;
  }
  return http.get('/employees', {
    params
  });
};

const createNewDriver = (data) => {
  data.roles = EMPLOYEE_TITLE_ROLES_MAP.DRIVER;
  data.status = 'active';
  data.title = 'DRIVER';
  data.title_cn = EMPLOYEE_TITLE_CN.DRIVER;
	return http.post('/employees', data);
};

const updateDriver = (id, data) => {
  if (!data.roles) {
    data.roles = ['driver'];
  } else {
    if (data.roles && !data.roles.includes('driver')) {
      data.roles.push('dirver');
    }
  }
  return http.put(`/employees/${id}`, data);
}

const deleteDriver = (id, data) => {
  data.status = 'inactive';
  return http.put(`/employees/${id}`, data);
}

const createNewDriverAsStaff = (data) => {
  data.roles = EMPLOYEE_TITLE_ROLES_MAP.DRIVER;
  data.status = 'active';
  data.title = 'DRIVER';
  data.title_cn = EMPLOYEE_TITLE_CN.DRIVER;
  data.tags = 'can_drive';
	return http.post('/staffs', data);
}

const updateDriverInStaff = (id, data) => {
  if (!data.roles) {
    data.roles = ['driver'];
  } else {
    if (data.roles && !data.roles.includes('driver')) {
      data.roles.push('dirver');
    }
  }
  return http.put(`/staffs/${id}`, data);
}

const getStaffsByNameOrEmail = (nameOrEmail) => {
  return http.get(`/staffs/search`, {params: {nameOrEmail}});
}

export const DriverService = {
  getAllActiveDrivers,
  createNewDriver,
  updateDriver,
  deleteDriver,
  createNewDriverAsStaff,
  updateDriverInStaff,
  getStaffsByNameOrEmail
};
