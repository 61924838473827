import React, {useState, useEffect} from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { customerSlice } from "./../../store";
import { AuthService, CustomerService, EventRequestsService, EventsService, ResourceService } from "../../services";
import Select from 'react-select';
import { Button, Modal } from "react-bootstrap";

const CreateEvent = () => {
  const navigate = useNavigate();
  const [resources, setResources] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(undefined);
	const [customerDisplay, setCustomerDisplay] = useState('');
	const [medicalResource, setMedicalResource] = useState(undefined)
	const [currentResource, setCurrentResource] = useState(undefined);
	const [resourceType, setResourceType] = useState('');
	const [source, setSource] = useState('');
	const [np, setNp] = useState('');
	const [symptom, setSymptom] = useState('');
	const [transportation, setTransportation] = useState('');
	const [showResourceModal, setShowResourceModal] = useState(false);
  const [keyword, setKeyword] = useState('');
	const [filteredResources, setFilteredResources] = useState([]);
	// const [upload, setUpload] = useState('');
	// const [uploadOther, setUploadOther] = useState('');
	const [type, setType] = useState('');
  
 
  // const params = new URLSearchParams(window.location.search);
  const goToRequestList = () => {
		navigate(`/medical/events/request/list`);
  }

	const redirectTo = () => {
		navigate(`/medical/index`);
	}


  const saveEventRequest = () => {
    const newEventRequest = {
      customer_id: currentCustomer?.id,
			customer_display: `${currentCustomer?.name} - ${currentCustomer?.name_cn} - ${currentCustomer?.birth_date}`,
			source,
			symptom,
			resource: currentResource?.id,
			resource_display: currentResource?.name,
			np,
			upload: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
			type,
			status: 'active',
      create_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
      create_date: new Date(),
			edit_history:[{ employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }]
    }

    console.log('new Event Request', newEventRequest);
    EventRequestsService.createNewEventRequest(newEventRequest).then(data => goToRequestList());
  };
  

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    Promise.all([ResourceService.getAll(), CustomerService.getAllCustomers()]).then(([resourcesData, customersData]) => {
      setResources(resourcesData.data);
			setFilteredResources(resourcesData.data);
      setCustomers(customersData.data);
    })
  }, []);

	useEffect(() => {
    const newResources = [...resources];
    
    if (resourceType && resourceType !== '') {
      setFilteredResources(newResources?.filter(item => item.type === resourceType));
    }
  }, [resourceType])

  const onCustomerChange = (selectedCustomer) => {
		setCustomerDisplay(selectedCustomer);
    setCurrentCustomer(customers.find(customer => customer.id === selectedCustomer.value))
  }

	const filterResourceListByKeyword = (item) => {
    if (keyword.length > 0) {
      const newKeyword = keyword.toLowerCase();
      return item?.name.toLowerCase()?.includes(newKeyword) || item?.address?.toLowerCase()?.includes(newKeyword) || item?.specialty?.toLowerCase()?.includes(newKeyword);
    } else {
      return true;
    }
  }

  const filterResourcesListBySpecialty = (item) => {
    if (resourceType.length > 0) {
      return item?.type === resourceType;
    } else {
      return true;
    }
  }

  // const onResourceChange = (selectedResource) => {
  //   setMedicalResource(selectedResource);
  //   setCurrentResource(resources.find(resource => resource.id === selectedResource.value));
  // }

  return (
    <>
      <div className="list row">
        <div className="col-md-8 col-xs-12">
          <div className="list row mb-4">
            <div className="col-md-12 text-primary">
              <h5>Create Event Request<button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
            </div>
          </div>
          <div className="list row mb-4">
						<div className="col-md-6 mb-4">
              <div>Source*:</div>
              <select value={source} onChange={e => setSource(e.target.value)}>
                <option value=""></option>
                {EventRequestsService.sourceList.map((item) => <option value={item.value}>{item.label}</option>)}
              </select>
            </div>
						<div className="col-md-6 mb-4">
              <div>Type*:</div>
              <select value={type} onChange={e => setType(e.target.value)}>
                <option value=""></option>
                <option value="New">New</option>
                <option value="Reschedule">Reschedule</option>
								<option value="Cancel">Cancel</option>
                <option value="Transportation Change">Transportation Change</option>
								<option value="Emergency">Emergency</option>
                <option value="Option 6">Option 6</option>
              </select>
            </div>
						<div className="col-md-6 mb-4">
              <div>Patient*:</div>
             
              <Select value={customerDisplay || ''} onChange={selectedCustomer => onCustomerChange(selectedCustomer)} options={[{value: '', label: ''}, ...customers.map(customer => ({
                value: customer?.id || '',
                label: `${customer?.name} - ${customer?.name_cn} - ${customer?.birth_date}` || ''
              }))]}></Select>
            </div>
            <div className="col-md-6 mb-4">
            	<div>Doctor:</div>
							{currentResource ? (<><span>{currentResource?.name}</span> <span><button className="btn btn-link btn-sm me-2 mb-2" onClick={()=> setShowResourceModal(true)}>Update</button></span></>)  : (<button className="btn btn-link btn-sm me-2 mb-2" onClick={()=> setShowResourceModal(true)}>Please click here to add resources</button>)}
              {/* <Select value={medicalResource || ''} onChange={selectedResource => onResourceChange(selectedResource)} options={[{value: '', label: ''}, ...resources.map(resource => ({
                value: resource.id || '',
                label: resource?.name || ''
              }))]}></Select> */}
            </div>
            <div className="col-md-12 mb-4">
              <div>Symptom 和特殊要求:</div> <textarea value={symptom || ''} onChange={e => setSymptom(e.target.value)}/>
            </div>
						<div className="col-md-6 mb-4">
              <div>Transportation:</div>
              <select value={transportation} onChange={e => setTransportation(e.target.value)}>
                <option value=""></option>
                <option value="By Own Transportation">By Own Transportation</option>
                <option value="Dropoff Only">Dropoff Only</option>
								<option value="Pickup Only">Pickup Only</option>
                <option value="Center Transportation">Center Transportation</option>
								<option value="Telephone">Telephone</option>
              </select>
            </div>
						<div className="col-md-6 mb-4">
              <div>NP:</div>
              <select value={np} onChange={e => setNp(e.target.value)}>
								<option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
						{/* <div className="col-md-6 mb-4">
              <div>Upload*:</div>
              <select value={upload} onChange={e => setUpload(e.target.value)}>
                <option value=""></option>
                <option value="Jiying Zhu">Jiying Zhu</option>
                <option value="Daiyuan Luo">Daiyuan Luo</option>
								<option value="Shu Zhang">Shu Zhang</option>
                <option value="Jing Lu">Jing Lu</option>
								<option value="Li Sun">Li Sun</option>
								<option value="Yu Wang">Yu Wang</option>
								<option value="Xu,Weiqin">Xu,Weiqin</option>
								<option value="Ma,Junpei">Ma,Junpei</option>
								<option value="Joyce Zhang">Joyce Zhang</option>
								<option value="Other">Other</option>
              </select>
            </div>
						{upload === 'Other' && <div className="col-md-6 mb-4">
							Upload By if Other:
							<input type="text" value={uploadOther || ''} onChange={e => setUploadOther(e.target.value)}/>
						</div>} */}
          </div>
          <div className="list row mb-5">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <button className="btn btn-primary btn-sm me-2 mb-2" onClick={() => saveEventRequest()}> Submit </button>
              <button className="btn btn-default btn-sm" onClick={() => redirectTo()}> Cancel </button>
            </div>
          </div>
        </div>
       </div> 
       <Modal show={showResourceModal} fullscreen={'xxl-down'} onHide={() => setShowResourceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select the Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">Type in Keyword to Filter: <input type="text" value={keyword} onChange={e => setKeyword(e.target.value)}/></div>
          <div className="mb-4">Filter By Resource Type:
          <select value={resourceType} onChange={e => setResourceType(e.target.value)}>
            <option value=""></option>
            <option value="doctor">Doctor</option>
            <option value="pharmacy">Pharmacy</option>
            <option value="hospital">Hospital</option>
            <option value="surgical center">Surgical Center</option>
            <option value="government agency">Government Agency</option>
            <option value="other">Other</option>
          </select>
          </div>
          
          <table className="personnel-info-table mb-4 medical"> 
            <thead>
              <tr className="sortable-header">
                <th>Name</th>
                <th>Specialty</th>
                <th>Address</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                resources?.filter(r=> r.status === 'active')?.filter((item => filterResourceListByKeyword(item)))?.filter(item => filterResourcesListBySpecialty(item)).map(resource => <tr key={resource.id}>
                  <td>{`${resource?.name || ''}${resource?.name ? '-': ''}${resource?.name_original || ''}${resource?.name_original ? '-': ''}${resource?.name_branch || ''}`}</td>
                  <td>{resource?.specialty}</td>
                  <td>{resource?.address}</td>
                  <td style={{'min-width': '220px'}}>{ resource?.id === currentResource?.id ? 'Selected' : (<Button variant="primary" className="me-2" size="sm" onClick={() => {setCurrentResource(resource); setShowResourceModal(false)}}>Select</Button>)}</td>
                </tr>)
              }
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowResourceModal(false)}>
            Complete And Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateEvent;