import React, { useEffect, useState } from "react";
import { TransRoutesService } from "../../services";
import { CSVLink } from "react-csv";
import moment from 'moment';

const RouteCustomerTable = ({transRoutes, sectionName, vehicles}) => {

	const [customers, setCustomers] = useState([]);
	
	useEffect(() => {
		setCustomers(TransRoutesService.getAllCustomersFromRoutes([...transRoutes], vehicles));
	})

	const generateCustomerTableVechileReportData = () => {
    const head = ['No.', 'Name', 'Table', 'Vehicle Number'];
    const chineseHead=['序号', '姓名', '桌号', '车号'];
    const content = [];
    let index = 1;
    for (let i=0; i<customers.length; i++) {
      content.push([index, customers[i]?.customer_name, customers[i]?.customer_table_id, customers[i]?.vehicle_number]);
      index++;
    }
    return [head, chineseHead, ...content ];
  }
	
  return (
    <>
      <div className="text-primary mb-4"><h5>{sectionName}</h5></div>
			<div className="list row mb-4">
        <div className="col-md-12">
        <CSVLink className="btn btn-primary btn-sm" data={generateCustomerTableVechileReportData()} filename={`Route Customer Table Vehcile Report`}>
          Generate Customer Table Vehicle Report
        </CSVLink>
        </div>
      </div>
      <div className="list row">
        <div className="col-md-12">
					<table className="personnel-info-table">
            <thead>
              <tr>
								<th>Name</th>
								<th>Table</th>
								<th>Vehicle No.</th>
							</tr>
						</thead>
						<tbody>
							{
								customers?.map((customer) => (
									<tr>
										<td>{customer?.customer_name}</td>
										<td>{customer?.customer_table_id}</td>
										<td>
											{customer?.vehicle_number}
										</td>
									</tr>
								))
							}
						</tbody>
					</table>
        </div>
      </div>
    </>
    
  );
};

export default RouteCustomerTable;