import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { customerSlice } from "./../../store";
import { AuthService, CustomerService, ResourceService } from "../../services";
import Select from 'react-select';
import { Modal, Button } from "react-bootstrap";
import { CUSTOMER_TYPE, PICKUP_STATUS, PICKUP_STATUS_TEXT , CUSTOMER_TYPE_TEXT} from "../../shared";

const UpdateCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateCustomer } = customerSlice.actions;
  const urlParams = useParams();
  const [currentCustomer, setCurrentCustomer] =  useState(undefined);
	const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
	const [nameCN, setNameCN] = useState('');
  const [birthDate, setBirthDate] = useState('');
	const [email, setEmail] = useState('');
	const [mobilePhone, setMobilePhone] = useState('');
	const [phone, setPhone] = useState('');
  const [homePhone, setHomePhone] = useState('');
  const [tableId, setTableId] = useState('');
  const [language, setLanguage] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [address4, setAddress4] = useState('');
  const [address5, setAddress5] = useState('');
  const [customerType, setCustomerType] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [careProvider, setCareProvider] = useState('');
  const [medicareNumber, setMedicareNumber] = useState('');
  const [medicaidNumber, setMedicaidNumber] = useState('');
  const [pharmacy, setPharmacy] = useState('');
  const [pharmacyId, setPharmacyId] = useState('');
  const [pickupStatus, setPickupStatus] = useState('');
  const [specialNeeds, setSpecialNeeds] = useState('');
  const [emergencyContact, setEmergencyContact] = useState('');
  const [admissionDate, setAdmissionDate] = useState('');
  const [vehicleNo, setVehicleNo] = useState('');
  const [note, setNote] = useState('');
  const [pin, setPin] = useState('');
  const [seating, setSeating] = useState('');
  const [caller, setCaller] = useState('');
  const [dischargeDate, setDischargeDate] = useState('');
  const [placement, setPlacement] = useState('');
  const [nickname, setNickname] = useState('');
  const [groups, setGroups] = useState('');
  const [tags, setTags] = useState('');
  const [roles, setRoles] = useState('');
  const [apartment, setApartment] = useState('');
  const [resources, setResources] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [privateNote, setPrivateNote] = useState('');
  const [disability, setDisability] = useState(false);
  const [gender, setGender] = useState('');
  const [weight, setWeight] = useState('');
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInch, setHeightInch] = useState('');
  const [textMsgEnabled, setTextMsgEnabled] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const redirectTo = () => {
		navigate(`/customers/list`);
  }
  const redirectToView = () => {
		navigate(`/customers/${urlParams.id}`);
  }

  useEffect(() => {
    if (!AuthService.canAddOrEditCustomers()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    if (!currentCustomer) {
      CustomerService.getCustomer(urlParams.id).then((data) => {
        setCurrentCustomer(data.data);
      })
    }
    ResourceService.getAll('pharmacy').then(data => {
      setResources(data.data);
    })
  }, []);

  const onPharmacyChange = (selectedPharmacy) => {
    setPharmacy(selectedPharmacy);
    setPharmacyId(selectedPharmacy?.value);
  }

  useEffect(() => {
    if (currentCustomer) {
      setUsername(currentCustomer.username);
      setFirstname(currentCustomer.firstname);
      setLastname(currentCustomer.lastname);
      setNameCN(currentCustomer.name_cn);
      setBirthDate(currentCustomer.birth_date);
      setRoles(currentCustomer.roles?.join(','));
      setEmail(currentCustomer.email);
      setMobilePhone(currentCustomer.mobile_phone);
      setPhone(currentCustomer.phone);
      setHomePhone(currentCustomer.home_phone);
      setLanguage(currentCustomer.language);
      setAddress1(currentCustomer.address1);
      setAddress2(currentCustomer.address2);
      setAddress3(currentCustomer.address3);
      setAddress4(currentCustomer.address4);
      setAddress5(currentCustomer.address5);
      setNote(currentCustomer.note);
      setTableId(currentCustomer.table_id);
      setCustomerType(currentCustomer.type);
      setCareProvider(currentCustomer.care_provider);
      setMedicareNumber(currentCustomer.medicare_number);
      setMedicaidNumber(currentCustomer.medicaid_number);
      setPharmacy({label: currentCustomer.pharmacy, value: currentCustomer.pharmacy_id});
      setPharmacyId(currentCustomer.pharmacy_id);
      setPickupStatus(currentCustomer.pickup_status);
      setSpecialNeeds(currentCustomer.setSpecial_needs);
      setEmergencyContact(currentCustomer.emergency_contact);
      setAdmissionDate(currentCustomer.admission_date);
      setDischargeDate(currentCustomer.discharge_date);
      setVehicleNo(currentCustomer.vehicle_no);
      setPin(currentCustomer.pin);
      setSeating(currentCustomer.seating);
      setCaller(currentCustomer.caller);
      setPlacement(currentCustomer.placement);
      setNickname(currentCustomer.nickname);
      setGroups(currentCustomer.groups?.join(','));
      setTags(currentCustomer.tags?.join(','));
      setApartment(currentCustomer.apartment);
      setPrivateNote(currentCustomer.private_note);
      setDisability(currentCustomer.disability ? 'true' : 'false');
      setWeight(currentCustomer.weight);
      setGender(currentCustomer.gender);
      setTextMsgEnabled(currentCustomer.text_msg_enabled ? 'true': 'false');
      setHeightFeet(currentCustomer.height && currentCustomer.height.length > 0? currentCustomer.height.replaceAll(' ', '')?.split('ft')[0] : '');
      setHeightInch(currentCustomer.height && currentCustomer.height.length > 0? currentCustomer.height.replaceAll(' ', '')?.split('ft')[1]?.split('in')[0] : '');
    }
    
  }, [currentCustomer]);

  const triggerShowDeleteModal = () => {
    setShowDeleteModal(true);
  }

  const deleteCustomer = () => {
    let data = {
			username,
      name: customerType === 'discharged' ? `${lastname},${firstname} (discharged)` : `${lastname},${firstname}`,  
      name_cn: nameCN,
      email,
      mobile_phone: mobilePhone,
      phone,
      table_id: tableId,
      home_phone: homePhone,
      type: customerType,
      language, 
      status: 'inactive',
      address1,
      address2,
      address3,
      address4,
      address5,
      firstname,
      lastname,
      birth_date: birthDate,
      care_provider: careProvider,
      medicare_number: medicareNumber,
      medicaid_number: medicaidNumber,
      pharmacy: pharmacy?.label || '',
      pharmacy_id: pharmacyId,
      pickup_status: pickupStatus,
      special_needs: specialNeeds,
      emergency_contact: emergencyContact,
      admission_date: admissionDate,
      vehicle_no: vehicleNo,
      discharge_date: dischargeDate,
      edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name || '',
      note,
      pin,
      seating,
      caller,
      placement,
      nickname,
      groups: groups?.replace(' ', '').split(','),
      tags: tags?.replace(' ', '').split(','),
      roles: roles?.replace(' ', '').split(','),
      apartment,
      private_note: privateNote,
      disability: disability === 'true' ? true : false,
      weight,
      gender,
      height: ((heightFeet?.length > 0 && heightInch?.length> 0 && `${heightFeet} ft ${heightInch} in`)) || '',
      text_msg_enabled: textMsgEnabled === 'true' || false
		};
    // const dataForLegacy = {
		// 	username,
    //   name: customerType === 'discharged' ? `${lastname},${firstname} (discharged)` : `${lastname},${firstname}`,  
    //   name_cn: `${lastname},${firstname} ${nameCN}`,
    //   email,
    //   password,
    //   mobile_phone: mobilePhone,
    //   phone,
    //   table_id: tableId,
    //   home_phone: homePhone,
    //   type: customerType,
    //   language, 
    //   status: 'active',
    //   address: address1,
    //   address2,
    //   firstname,
    //   lastname,
    //   birth_date: birthDate,
    //   care_provider: careProvider,
    //   medicare_number: medicareNumber,
    //   medicaid_number: medicaidNumber,
    //   pharmacy: pharmacy?.label || '',
    //   pharmacy_id: pharmacyId,
    //   emergency_contact: emergencyContact,
    //   admission_date: admissionDate,
    //   vehicle_no: vehicleNo,
    //   discharge_date: dischargeDate,
    //   edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name || '',
    //   note,
    //   pin,
    //   seating,
    //   caller,
    //   placement,
    //   nickname,
    //   groups: groups.replace(' ', '').split(','),
    //   tags: tags.replace(' ', '').split(','),
    //   roles: roles.replace(' ', '').split(','),
    //   private_note: privateNote
		// };
    if (password && password.length > 0) {
      data = Object.assign({}, data, {password});
      // dataForLegacy = Object.assign({}, dataForLegacy, {password});
    }
    const formData = new FormData();
  	formData.append("file", selectedFile);
    let payload = {id: urlParams.id, data, currentCustomer};
    if (selectedFile) {
      console.log('formData');
      payload = Object.assign({}, payload, {avatar: formData})
    }
    dispatch(updateCustomer(payload));
    setShowDeleteModal(false);
		redirectTo();
  };


  const saveCustomer = () => {
    let data = {
			username,
      name: customerType === 'discharged' ? `${lastname},${firstname} (discharged)` : `${lastname},${firstname}`,  
      name_cn: nameCN,
      email,
      mobile_phone: mobilePhone,
      phone,
      table_id: tableId,
      home_phone: homePhone,
      type: customerType,
      language, 
      status: 'active',
      address1,
      address2,
      address3,
      address4,
      address5,
      firstname,
      lastname,
      birth_date: birthDate,
      care_provider: careProvider,
      medicare_number: medicareNumber,
      medicaid_number: medicaidNumber,
      pharmacy: pharmacy?.label || '',
      pharmacy_id: pharmacyId,
      pickup_status: pickupStatus,
      special_needs: specialNeeds,
      emergency_contact: emergencyContact,
      admission_date: admissionDate,
      vehicle_no: vehicleNo,
      discharge_date: dischargeDate,
      edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name || '',
      note,
      pin,
      seating,
      caller,
      placement,
      nickname,
      groups: groups?.replace(' ', '').split(','),
      tags: tags?.replace(' ', '').split(','),
      roles: roles?.replace(' ', '').split(','),
      apartment,
      private_note: privateNote,
      disability: disability === 'true' ? true : false,
      weight,
      gender,
      height: `${heightFeet} ft ${heightInch} in`,
      text_msg_enabled: textMsgEnabled === 'true' || false
		};
    // const dataForLegacy = {
		// 	username,
    //   name: customerType === 'discharged' ? `${lastname},${firstname} (discharged)` : `${lastname},${firstname}`,  
    //   name_cn: `${lastname},${firstname} ${nameCN}`,
    //   email,
    //   password,
    //   mobile_phone: mobilePhone,
    //   phone,
    //   table_id: tableId,
    //   home_phone: homePhone,
    //   type: customerType,
    //   language, 
    //   status: 'active',
    //   address: address1,
    //   address2,
    //   firstname,
    //   lastname,
    //   birth_date: birthDate,
    //   care_provider: careProvider,
    //   medicare_number: medicareNumber,
    //   medicaid_number: medicaidNumber,
    //   pharmacy: pharmacy?.label || '',
    //   pharmacy_id: pharmacyId,
    //   emergency_contact: emergencyContact,
    //   admission_date: admissionDate,
    //   vehicle_no: vehicleNo,
    //   discharge_date: dischargeDate,
    //   edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name || '',
    //   note,
    //   pin,
    //   seating,
    //   caller,
    //   placement,
    //   nickname,
    //   groups: groups.replace(' ', '').split(','),
    //   tags: tags.replace(' ', '').split(','),
    //   roles: roles.replace(' ', '').split(','),
    //   private_note: privateNote
		// };

    if (password && password.length > 0) {
      data = Object.assign({}, data, {password});
      // dataForLegacy = Object.assign({}, dataForLegacy, {password});
    }
    const formData = new FormData();
  	formData.append("file", selectedFile);
    let payload = {id: urlParams.id, data, currentCustomer};
    if (selectedFile) {
      console.log('file', selectedFile);
      console.log('formData', formData);
      payload = Object.assign({}, payload, {avatar: formData})
    }
    dispatch(updateCustomer(payload));
    setTimeout(() => {
      redirectToView();
    }, 3000);
		
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  }

  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Update Customer <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-4 mb-4">
					<div>First Name:(*)</div> <input type="text" value={firstname || ''} onChange={e => setFirstname(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Last Name:(*)</div> <input type="text" value={lastname || ''} onChange={e => setLastname(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Chinese Name (中文姓名):</div> <input type="text" value={nameCN || ''} onChange={e => setNameCN(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Email:(*)</div> <input type="email" value={email || ''} onChange={e => setEmail(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Care Provider:</div> <input type="text" value={careProvider || ''} onChange={e => setCareProvider(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Emergency Contact:</div> <input type="text" value={emergencyContact || ''} onChange={e => setEmergencyContact(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Medicare Number:</div> <input type="text" value={medicareNumber || ''} onChange={e => setMedicareNumber(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Medicaid Number:</div> <input type="text" value={medicaidNumber || ''} onChange={e => setMedicaidNumber(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
        <div>Pharmacy:</div>
          <Select value={pharmacy || ''} onChange={selectedData => onPharmacyChange(selectedData)} options={[{value: '', label: ''}, ...resources.map(resource => ({
            value: resource?.id || '',
            label: resource?.name || '',
          }))]}></Select>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Pharmacy ID:</div> <input type="text" value={pharmacyId || ''} onChange={e => setPharmacyId(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Address 1:</div> <input type="text" value={address1 || ''} onChange={e => setAddress1(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Address 2:</div> <input type="text" value={address2 || ''} onChange={e => setAddress2(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Address 3:</div> <input type="text" value={address3 || ''} onChange={e => setAddress3(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Address 4:</div> <input type="text" value={address4 || ''} onChange={e => setAddress4(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Address 5:</div> <input type="text" value={address5 || ''} onChange={e => setAddress5(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Apartment:</div> <input type="text" value={apartment || ''} onChange={e => setApartment(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Table Id:</div> <input type="text" value={tableId || ''} onChange={e => setTableId(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Customer Type:</div> <select value={customerType} onChange={e => setCustomerType(e.target.value)}>
            <option value=""></option>
            {
              Object.keys(CUSTOMER_TYPE).map((key, index) => <option key={index} value={CUSTOMER_TYPE[key]}> {CUSTOMER_TYPE_TEXT[CUSTOMER_TYPE[key]]}</option>)
            }
          </select>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Pickup Status:</div> <select value={pickupStatus} onChange={e => setPickupStatus(e.target.value)}>
            <option value=""></option>
            {
              Object.keys(PICKUP_STATUS).map((key, index) => <option key={index} value={PICKUP_STATUS[key]}> {PICKUP_STATUS_TEXT[PICKUP_STATUS[key]]}</option>)
            }
          </select>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Upload Avatar (Image size should be less than 500 KB):</div>
          <input
            type="file"
            onChange={(e) => setSelectedFile(e.target.files[0])}
            className="form-control-file border"
          />

        </div>
				<div className="col-md-4 mb-4">
					<div>Birth Date (Type in as: MM/DD/YYYY):</div> <input type="text" value={birthDate || ''} onChange={e => setBirthDate(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Phone :</div> <input type="text" value={phone || ''} onChange={e => setPhone(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Mobile Phone:</div> <input type="text" value={mobilePhone || ''} onChange={e => setMobilePhone(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Home Phone:</div> <input type="text" value={homePhone || ''} onChange={e => setHomePhone(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Special Needs:</div> <input type="text" value={specialNeeds || ''} onChange={e => setSpecialNeeds(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Language(Please use ',' between each language):</div> <input type="text" value={language || ''} onChange={e => setLanguage(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Username (not required):</div> <input type="text" value={username || ''} onChange={e => setUsername(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Password (not required):</div> <input type="text" value={password || ''} onChange={e => setPassword(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Nick Name:</div> <input type="text" value={nickname || ''} onChange={e => setNickname(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Note:</div> <input type="text" value={note || ''} onChange={e => setNote(e.target.value)}/>
        </div>
				
				<div className="col-md-4 mb-4">
			  	<div>Admission Date(Type in as 'MM/DD/YYYY'):</div> <input type="text" value={admissionDate || ''} onChange={e => setAdmissionDate(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Discharge Date(Type in as 'MM/DD/YYYY'):</div> <input type="text" value={dischargeDate || ''} onChange={e => setDischargeDate(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Pin:</div> <input type="text" value={pin || ''} onChange={e => setPin(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Seating:</div> <input type="text" value={seating || ''} onChange={e => setSeating(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Vehicle No:</div> <input type="text" value={vehicleNo || ''} onChange={e => setVehicleNo(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Caller:</div> <input type="text" value={caller || ''} onChange={e => setCaller(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Placement:</div> <input type="text" value={placement || ''} onChange={e => setPlacement(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Groups(Please use ',' between each group):</div> <input type="text" value={groups || ''} onChange={e => setGroups(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Tags(Please use ',' between each tags):</div> <input type="text" value={tags || ''} onChange={e => setTags(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Roles(Please use ',' between each roles):</div> <input type="text" value={roles || ''} onChange={e => setRoles(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Private Note:</div> <input type="text" value={privateNote || ''} onChange={e => setPrivateNote(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Disability:</div> <select value={disability} onChange={e => setDisability(e.target.value)}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Height:</div> 
          <input type="text" value={heightFeet || ''} onChange={e => setHeightFeet(e.target.value)}/> Ft 
          <input type="text" value={heightInch || ''} onChange={e => setHeightInch(e.target.value)}/> In
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Weight:</div> <input type="text" value={weight || ''} onChange={e => setWeight(e.target.value)}/> lb
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Gender:</div> <select value={gender} onChange={e => setGender(e.target.value)}>
            <option value=""></option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Enable Text Message:</div> <select value={textMsgEnabled} onChange={e => setTextMsgEnabled(e.target.value)}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      </div>
      <div className="list row mb-5">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <button className="btn btn-primary btn-sm me-2 mb-2" onClick={() => saveCustomer()}> Save </button>
          <button className="btn btn-danger btn-sm me-2 mb-2" onClick={() => triggerShowDeleteModal()}> Delete </button>
          <button className="btn btn-default btn-sm" onClick={() => redirectTo()}> Cancel </button>
        </div>
      </div>
      <Modal show={showDeleteModal} onHide={() => closeDeleteModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this customer?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeDeleteModal()}>
            No
          </Button>
          <Button variant="primary" onClick={() => deleteCustomer()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateCustomer;