import { createSlice, CaseReducer } from "@reduxjs/toolkit";

const initialState = {
  vehicles: [],
  loading: false,
  error: null
};


const fetchAllVehicles = (state, action) => {
  state.loading = true;
}
const fetchAllVehiclesSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.vehicles = action.payload;
}
const fetchAllVehiclesFailure = (state, action) => {
  state.vehicles = [];
  state.error = action.payload;
}
const updateVehicle = (state, action) => {};
const updateVehicleFailure = (state, action) => {
  state.error = action.payload;
}
const createVehicle = (state, action) => {};
const createVehicleFailure = (state, action) => {
  state.error = action.payload;
}

const deleteVehicle = (state, action) => {};
const deleteVehicleFailure = (state, action) => {
  state.error = action.payload;
}


export const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    fetchAllVehicles,
    fetchAllVehiclesSuccess,
    fetchAllVehiclesFailure,
    updateVehicle,
    updateVehicleFailure,
    createVehicleFailure,
    createVehicle,
    deleteVehicle,
    deleteVehicleFailure
  }
});

