import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { userSlice } from './users';
import { transRoutesSlice } from './trans-routes';
import { driverSlice } from './drivers';
import { vehicleSlice } from './vehicles';
import { employeeSlice } from './employees';
import { customerSlice } from './customers';
import { transRouteTemplatesSlice } from './trans-routes-templates';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './../effects';

const rootReducer = combineReducers({
  users: userSlice.reducer,
  transRoutes: transRoutesSlice.reducer,
  drivers: driverSlice.reducer,
  vehicles: vehicleSlice.reducer,
  employees: employeeSlice.reducer,
  transRouteTemplates: transRouteTemplatesSlice.reducer,
  customers: customerSlice.reducer
})

const sagaMiddleware = createSagaMiddleware()
const store = configureStore(
  {
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
      thunk: false
    }).concat(sagaMiddleware)
  }
)
sagaMiddleware.run(rootSaga);

export default store;