import http from "../http-common";
import {EMPLOYEE_ROLES} from "../shared";
const login = (data) => {
  return http.post('/auth/login', data);
};

const logout = (data) => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  window.location.href="/login";
};

const isAdmin = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && roles?.includes('admin');
}

// const isDispatcher = () => {
//   const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
//   return roles && (roles?.includes('dispatcher') || roles?.includes('admin'));
// }

const canCreateOrEditDrivers = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.DRIVER_EDITOR) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canViewDrivers = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.DRIVER_VIEWER) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canViewEmployees = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.EMPLOYEE_VIEWER) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canAddOrEditEmployees = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.EMPLOYEE_EDITOR) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canAddOrEditRoutes = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.ROUTE_EDITOR) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canViewRoutes = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.ROUTE_VIEWER) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canViewVechiles = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.VEHICLE_VIEWER) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canAddOrEditVechiles = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.VEHICLE_EDITOR) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canViewCustomers = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.CUSTOMER_VIEWER) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canAddOrEditCustomers = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.CUSTOMER_EDITOR) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canViewAttendance = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.ATTENDANCE_VIEWER) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canAddOrEditAttendance = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.ATTENDANCE_EDITOR) || roles?.includes(EMPLOYEE_ROLES.ADMIN));
}

const canAddOrEditMedical = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_EDITOR) || roles?.includes(EMPLOYEE_ROLES.MEDICAL_RESERVATION_INFO_VIEWER));
}

const canAddOrEditMedicalSchedule = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_EDITOR) || roles?.includes(EMPLOYEE_ROLES.MEDICAL_RESERVATION_SCHEDULE_VIEWER));
}

const canAddOrEditResources = () => {
  const roles = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.roles;
  return roles && (roles?.includes(EMPLOYEE_ROLES.RESOURCE_LIST_EDITOR) || roles?.includes(EMPLOYEE_ROLES.RESOURCE_LIST_VIEWER));
}

const canAccessLegacySystem = () => {
  return isAdmin() || canAddOrEditAttendance() || canAddOrEditMedical() || canAddOrEditMedicalSchedule() || canAddOrEditResources();
}

const getLocalAccessToken = () => {
  return localStorage.getItem('token');
}


export const AuthService = {
  login,
  logout,
  isAdmin,
  canCreateOrEditDrivers,
  getLocalAccessToken,
  canViewDrivers,
  canViewEmployees,
  canAddOrEditEmployees,
  canAddOrEditRoutes,
  canViewRoutes,
  canViewVechiles,
  canAddOrEditVechiles,
  canViewCustomers,
  canAddOrEditCustomers,
  canAddOrEditAttendance,
  canViewAttendance,
  canAccessLegacySystem
};
