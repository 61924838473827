import { createSlice, CaseReducer } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  loading: false,
  error: null
};

const createCustomer = (state, action) => {}
const createCustomerFailure = (state, action) => {
  state.error = action.payload;
}

const fetchCustomers = (state, action) => {}
const fetchCustomersFailure = (state, action) => {
  state.error = action.payload;
}

const updateCustomer = (state, action) => {};
const updateCustomerFailure = (state, action) => {
  state.error = action.payload;
};

const deleteCustomer = (state, action) => {};
const deleteCustomerFailure = (state, action) => {
  state.error = action.payload;
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    createCustomer,
    createCustomerFailure,
    updateCustomer,
    updateCustomerFailure,
    deleteCustomer,
    deleteCustomerFailure,
    fetchCustomers,
    fetchCustomersFailure
  }
});

