import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { AuthService, EventsService, CustomerService, ResourceService } from "../../services";
import moment from 'moment';
import { Button, Modal } from "react-bootstrap";
import { Scheduler } from "@aldabil/react-scheduler";


const EventsCalendar = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [customers, setCustomers] = useState([]);
	const [resources, setResources] = useState([]);
	const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [toDate, setToDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
	const [currentTotalTranslate1, setCurrentTotalTranslate1] = useState(0);
	const [currentTotalTranslate2, setCurrentTotalTranslate2] = useState(0);
	const [currentTotalResource, setCurrentTotalResource] = useState(0);
	const [showDeletedItems, setShowDeletedItems] = useState(false);
	const [timeData, setTimeData] = useState([]);

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    CustomerService.getAllCustomers().then((data) => {
      setCustomers(data.data);
    });
		ResourceService.getAll().then((data) => {
			setResources(data.data);
		});
		EventsService.getTimeData().then(data => {
			setTimeData(data.data);
		});
  }, []);

  useEffect(() => {
		if (customers?.length > 0 && resources.length > 0) {
    	EventsService.getAllEvents({ from: EventsService.formatDate(fromDate), to: EventsService.formatDate(toDate) }).then((data) => {
      	setEvents(data.data.filter((item) => {
					const customerField = item?.data?.customer ? (customers?.find(c => c.id === item?.data?.customer)?.name || item?.data?.client_name || '') : (item?.data?.client_name || '');
					const doctorField = item?.data?.resource ? ((resources?.find(r => r.id === item?.data?.resource))?.name || item?.data?.resource_name || '') : (item?.data?.resource_name || '');
					item.event_id = item.id;
        	item.customer = customerField;
        	item.doctor = doctorField;
        	item.phone = item?.data?.resource ? ((resources?.find(r => r.id === item?.data?.resource))?.phone || item?.data?.resource_phone || '') : (item?.data?.resource_phone || '');
					item.contact = item?.data?.resource? ((resources?.find(r => r.id === item?.data?.resource))?.contact || item?.data?.resource_contact || '') : (item?.data?.resource_contact || '')
					item.address = item?.data?.resource ? ((resources?.find(r => r.id === item?.data?.resource))?.address || item?.data?.resource_address || '') : (item?.data?.resource_address || '');
					item.translation = item?.data?.interpreter || '';
					item.newPatient = item?.data?.new_patient || '';
					item.needId = item?.data?.need_id || '';
					item.disability = item?.data?.disability || '';
					item.startTime = item?.start_time? `${new Date(item?.start_time).toLocaleDateString()} ${moment(new Date(item?.start_time)).format('hh:mm A')}` : '' ;
					item.fasting = item?.data?.fasting || '';
					item.transportation = item?.link_event_name || '';
					item.title = `${moment(new Date(item?.start_time)).format('hh:mm A') || ''} ${doctorField} / ${customerField}`;
					item.start = new Date(item.start_time);
					item.end = item.stop_time? new Date(item.stop_time): new Date(item.start_time);
					const transportationInfo = EventsService.getTransportationInfo(data.data, item, timeData);
					const { isFutureEvent, maxTranslate1, maxTranslate2, maxResource, totalTranslate1, totalTranslate2, totalResource} = transportationInfo;
					item.color = item?.color;
					item.showWarnings = isFutureEvent;
					item.maxTranslate1 = maxTranslate1;
					item.maxTranslate2 = maxTranslate2;
					item.maxResource = maxResource;
					item.totalTranslate1 = totalTranslate1;
					setCurrentTotalTranslate1(item.totalTranslate1);
					item.totalTranslate2 = totalTranslate2;
					setCurrentTotalTranslate2(item.totalTranslate2);
					item.totalResource = totalResource;
					setCurrentTotalResource(item.totalResource);
					return item;
				}).filter(item => item.type === 'medical'));
      
    })}
  }, [fromDate, toDate, customers, resources, timeData]);

  const redirectToAdmin = () => {
    navigate(`/medical`)
  }

  const goToEdit = (id) => {
    navigate(`/medical/events/edit/${id}?from=calendar`)
  }

  const goToCreateNew = () => {
    navigate(`/medical/events`)
  }

  const goToList = () => {
	navigate(`/medical/events/list`)
  }

  const goToMultipleList = () => {
	navigate(`/medical/events/multiple-list`)
  }


  const goToView = (id) => {
    navigate(`/medical/events/${id}`)
  }

	const disableEvent = (id) => {
		const currentEvent = events.find(item => item.id === id);
		EventsService.disableEvent(id, { status: 'inactive', edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
		edit_date: new Date(),
		edit_history: currentEvent?.edit_history? [...currentEvent.edit_history, { employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }] : [{ employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }]}).then(() => {
			EventsService.getAllEvents({ from: EventsService.formatDate(fromDate), to: EventsService.formatDate(toDate) }).then((data) => {
				setEvents(data.data.filter((item) => {
					const customerField = item?.data?.customer ? (customers?.find(c => c.id === item?.data?.customer)?.name || item?.data?.client_name || '') : (item?.data?.client_name || '');
					const doctorField = item?.data?.resource ? ((resources?.find(r => r.id === item?.data?.resource))?.name || item?.data?.resource_name || '') : (item?.data?.resource_name || '');
					item.event_id = item.id;
        	item.customer = customerField;
        	item.doctor = doctorField;
        	item.phone = item?.data?.resource ? ((resources?.find(r => r.id === item?.data?.resource))?.phone || item?.data?.resource_phone || '') : (item?.data?.resource_phone || '');
					item.contact = item?.data?.resource? ((resources?.find(r => r.id === item?.data?.resource))?.contact || item?.data?.resource_contact || '') : (item?.data?.resource_contact || '')
					item.address = item?.data?.resource ? ((resources?.find(r => r.id === item?.data?.resource))?.address || item?.data?.resource_address || '') : (item?.data?.resource_address || '');
					item.translation = item?.data?.interpreter || '';
					item.newPatient = item?.data?.new_patient || '';
					item.needId = item?.data?.need_id || '';
					item.disability = item?.data?.disability || '';
					item.startTime = item?.start_time? `${new Date(item?.start_time).toLocaleDateString()} ${moment(new Date(item?.start_time)).format('hh:mm A')}` : '' ;
					item.fasting = item?.data?.fasting || '';
					item.transportation = item?.link_event_name || '';
					item.title = `${moment(new Date(item?.start_time)).format('hh:mm A') || ''} ${doctorField} / ${customerField}`;
					item.start = new Date(item.start_time);
					item.end = item.stop_time? new Date(item.stop_time): new Date(item.start_time);
					const transportationInfo = EventsService.getTransportationInfo(data.data, item, timeData);
					const { isFutureEvent, maxTranslate1, maxTranslate2, maxResource, totalTranslate1, totalTranslate2, totalResource} = transportationInfo;
					item.color = item?.color;
					item.showWarnings = isFutureEvent;
					item.maxTranslate1 = maxTranslate1;
					item.maxTranslate2 = maxTranslate2;
					item.maxResource = maxResource;
					item.totalTranslate1 = totalTranslate1;
					setCurrentTotalTranslate1(item.totalTranslate1);
					item.totalTranslate2 = totalTranslate2;
					setCurrentTotalTranslate2(item.totalTranslate2);
					item.totalResource = totalResource;
					setCurrentTotalResource(item.totalResource);
					return item;
				}).filter(item => item.type === 'medical'));;
			})
		});
	}
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Medical Event Calendar <button className="btn btn-link btn-sm" onClick={() => {redirectToAdmin()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
				<div className="col-md-12 col-sm-12 mb-4">
				Show Deleted Events: <input type="checkbox" value={showDeletedItems} checked={showDeletedItems === true} onClick={() => setShowDeletedItems(!showDeletedItems)} />
				
				</div>
        <div className="col-md-12 col-sm-12 mb-4">
				<Scheduler
					view="month"
					height={5000}
					events={showDeletedItems ? events : events.filter(event => event.status === 'active')}
					month = {{
						weekDays: [0, 1, 2, 3, 4, 5, 6], 
						weekStartOn: 0, 
						startHour: 0, 
						endHour: 24,
						navigation: true,
						disableGoToDay: false
					}}
					day = {{
						startHour: 0, 
						endHour: 24, 
						step: 60,
						navigation: true
					}}
					onSelectedDateChange = {(date) => {setFromDate(new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1)); setToDate(new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0))}}
					onEventEdit = {(event) => goToEdit(event.event_id)}
					onDelete = {(id) => disableEvent(id)}
					viewerExtraComponent = {(fields, event) => {
						return (<div>
							<div>{`Client: ${event.customer}`}</div>
							<div>{`Doctor: ${event.doctor}`}</div>
							<div>{`Contact: ${event.contact}`} </div>
							<div>{`Address: ${event.address}`} </div>
							<hr />
						</div>)
					}}
				/>
        </div>
      </div>
    </>
  )
};

export default EventsCalendar;