import React, { useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthService } from "../../services";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const MedicalIndex = () => {
  const navigate = useNavigate();
  const goToAdmin = () => {
    navigate(`/admin`);
  }
  const goToCreateResource = () => {
    navigate(`/medical/resources`);
  }

  const goToResourcesList = () => {
    navigate(`/medical/resources/list`);
  }

  const goToMedicalList = () => {
    navigate(`/medical/events/list`);
  }

  const goToMultipleMedicalList = () => {
    navigate(`/medical/events/multiple-list`);
  }

  const goToCalendarList = () => {
    navigate(`/medical/events/calendar`);
  }

  const goToCreateMedicalEvent = () => {
    navigate(`/medical/events`)
  }

  const goToRequestList = () => {
    navigate(`/medical/events/request/list`);
  }

  const goToCreateRequest = () => {
    navigate(`/medical/events/request`)
  }

  
  const goToCreateMessage = () => {
    navigate(`/messages/?from=medical`);
  }

  const goToMessageList = () => {
    navigate(`/messages/list?from=medical`);
  }

  const goToCreatePhone = () => {
    navigate(`/center-phones?from=medical`)
  }

  const goToPhoneList = () => {
    navigate(`/center-phones/list?from=medical`)
  }

  const goToCreateMessageToken = () => {
    navigate(`/message-tokens?from=medical`)
  }

  const goToSendMessage = () => {
    navigate(`/messages/send-message?from=medical`)
  }

  const goToAllSentMessages = () => {
    navigate(`/messages/sent-messages/list?from=medical`)
  }

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
        
        window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
        AuthService.logout();
        navigate(`/login`);
        
    }
  }, [])
  return (
    <>
        <div className="list row">
            <div className="col-md-12 mb-4">
                <div className="float-end"><button className="btn btn-link btn-sm" onClick={() => AuthService.logout()}>Log Out</button></div>
                <strong>You can click on the links below to manage medical events and resources: </strong>
            </div>
            <div className="medical">
                <div class="mb-4">
                    <h6>Medical Events Management</h6>
                    <ul>
                      <li onClick={() => goToCreateMedicalEvent()}>Create Medical Appointment</li>
                      <li onClick={() => goToMedicalList()}>Medical Appointment One-day List (for Driver Assignment)</li>
                      <li onClick={() => goToMultipleMedicalList()}>Medical Appointment Multi-day List (for Documents)</li>
                      <li onClick={() => goToCalendarList()}>Medical Appointment Calendar</li>
                      <li onClick={() => goToSendMessage()}>Send Messages</li>
                    </ul>
                </div>
                <div class="mb-4">
                    <h6>Resources Management</h6>
                    <ul>
                        <li onClick={() => goToResourcesList()}>Resources List</li>
                        <li onClick={() => goToCreateResource()}>Add Resource</li>
                    </ul>
                </div>
                <div class="mb-4">
                    <h6>Medical Appointments Requests Management</h6>
                    <ul>
                        <li onClick={() => goToRequestList()}>Medical Appointment Requests List</li>
                        <li onClick={() => goToCreateRequest()}>Add Medical Appointment Request</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
    
  );
};

export default MedicalIndex;