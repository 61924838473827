import http from "../http-common";

const getAllEventRequests = (params) => {
  return http.get('/event-requests', { params});
};

const createNewEventRequest = (data) => {
  data.status = 'active';
  return http.post('/event-requests', data);
};

const updateEventRequest = (id, data) => {
  return http.put(`/event-requests/${id}`, data);
}

const deleteEventRequest = (id) => {
	return http.delete(`/event-requests/${id}`)
}

const sourceList = [
	{
		value: 'byClient',
		label: '老人要求 By Client'
	},
	{
		value: 'byDoctor',
		label: '医生要求 By Doctor'
	},
	{
		value: 'returnTkt',
		label: '回诊单 Return Tkt'
	},
	{
		value: 'bySocialWorker',
		label: '社工部 By Social Worker'
	},
	{
		value: 'byTransportation',
		label: '交通部 By Transportation'
	},
	{
		value: 'byWsNursing',
		label: '护士站 By WS Nursing'
	},
	{
		value: 'forDoctorReport',
		label: '要报告 For Doctor Report'
	},
]

export const EventRequestsService = {
  createNewEventRequest,
  getAllEventRequests,
  updateEventRequest,
  deleteEventRequest,
	sourceList
};