import React, { useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthService } from "../../services";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Medical = () => {
  const navigate = useNavigate();
  const goToAdmin = () => {
    navigate(`/admin`);
  }
  const goToCreateResource = () => {
    navigate(`/medical/resources`);
  }

  const goToMedical = () => {
    navigate(`/medical/index`);
  }

  const goToResourcesList = () => {
    navigate(`/medical/resources/list`);
  }

  const goToMedicalList = () => {
    navigate(`/medical/events/list`);
  }

  const goToMultipleMedicalList = () => {
    navigate(`/medical/events/multiple-list`);
  }

  const goToCalendarList = () => {
    navigate(`/medical/events/calendar`);
  }

  const goToCreateMedicalEvent = () => {
    navigate(`/medical/events`)
  }

  
  const goToCreateMessage = () => {
    navigate(`/messages/?from=medical`);
  }

  const goToMessageList = () => {
    navigate(`/messages/list?from=medical`);
  }

  const goToCreatePhone = () => {
    navigate(`/center-phones?from=medical`)
  }

  const goToPhoneList = () => {
    navigate(`/center-phones/list?from=medical`)
  }

  const goToCreateMessageToken = () => {
    navigate(`/message-tokens?from=medical`)
  }

  const goToSendMessage = () => {
    navigate(`/messages/send-message?from=medical`)
  }

  const goToAllSentMessages = () => {
    navigate(`/messages/sent-messages/list?from=medical`)
  }

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
        
        window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
        AuthService.logout();
        navigate(`/login`);
        
    }
  }, [])
  return (
    <>
        <div className="list row">
            <div className="col-md-12 noprint">
                <div className="float-end"><button className="btn btn-link btn-sm" onClick={() => AuthService.logout()}>Log Out</button></div>
                <h4>Medical Center </h4> 
            </div>
            <div className="col-md-12 noprint">
                <Navbar bg="light" expand="lg" className="admin-nav mb-4">
                  <Container>
                    {/* <Navbar.Brand>Customers Admin</Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      {(AuthService.canViewRoutes() || AuthService.canAddOrEditRoutes() || AuthService.canAccessLegacySystem()) && <Nav className="me-auto">
                        <NavDropdown title="Messages Templates" id="basic-nav-dropdown">
                          <NavDropdown.Item  onClick={() => goToCreateMessage()}>
                            Create New Message Template
                          </NavDropdown.Item>
                          <NavDropdown.Item   onClick={() => goToMessageList()}>
                            View Message Templates List
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>}
                      {AuthService.canAccessLegacySystem() && <Nav className="me-auto">
                        <Nav.Link onClick={() => goToMedical()}>Medical</Nav.Link>
                      </Nav>}
                      {AuthService.canAccessLegacySystem() && <Nav className="me-auto">
                        <NavDropdown title="Resource" id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={() => goToCreateResource()}>
                            Create Resource
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => goToResourcesList()}>
                            View Resources List
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>}
                      {(AuthService.canViewRoutes() || AuthService.canAddOrEditRoutes() || AuthService.canAccessLegacySystem()) &&<Nav className="me-auto">
                        <NavDropdown title="Admin Phone" id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={() => goToCreatePhone()}>
                            Create New Admin Phone
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => goToPhoneList()}>
                            View Admin Phones List
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>}
                      {(AuthService.canViewRoutes() || AuthService.canAddOrEditRoutes()) && <Nav className="me-auto">
                        <NavDropdown title="Messages" id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={() => goToSendMessage()}>
                            Send Message
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => goToCreateMessageToken()}>
                            Edit Message Token
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => goToAllSentMessages()}>
                            View All Sent Messages
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>}
                      {(AuthService.isAdmin()) && <Nav.Link onClick={() => goToAdmin()}>Transportation</Nav.Link>}
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
            </div>
        </div>
        <div className="list row">
            <div className="col-md-12">
                <Outlet />
            </div>
        </div>
    </>
    
  );
};

export default Medical;