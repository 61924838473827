import { takeEvery, all, call, put } from 'redux-saga/effects';
import { customerSlice } from './../store';
import { CustomerService } from './../services';

const { createCustomer, createCustomerFailure, updateCustomer, updateCustomerFailure, deleteCustomer, deleteCustomerFailure } = customerSlice.actions;

function* createCustomerSaga(action) {
    try {
      const customer = yield call(CustomerService.createNewCustomer, action.payload.data);
      const client = yield call(CustomerService.createNewClient, action.payload.dataForLegacy);
      if (action.payload.avatar) {
        if (customer?.data?.id) {
          CustomerService.uploadAvatar(customer.data.id, action.payload.avatar)
        }
      }
    } catch(ex) {
      yield put(createCustomerFailure(ex));
    }
  }

function* updateCustomerSaga(action) {
  try {
    yield call(CustomerService.updateCustomer, action.payload.id, action.payload.data);
    // const targetClients = yield call(CustomerService.getClientsByNameOrEmail, action.payload.currentCustomer.email);
    // console.log(targetClients.data);
    // if (targetClients?.data?.length > 0) {
    //   yield call(CustomerService.updateClient, targetClients?.data[0]?.id, action.payload.dataForLegacy);
    // }
    if (action.payload.avatar) {
      CustomerService.uploadAvatar(action.payload.id, action.payload.avatar)
    }
  } catch(ex) {
    yield put(updateCustomerFailure(ex));
  }
}

function* deleteCustomerSaga(action) {
  try {
    yield call(CustomerService.deleteCustomer, action.payload.id, action.payload.data);
    // yield call(CustomerService.deleteCustomer, action.payload.id, action.payload.dataForLegacy)
  } catch(ex) {
    yield put(deleteCustomerFailure(ex));
  }
}

export function* customerEffect() {
  yield all([
    yield takeEvery(createCustomer.type, createCustomerSaga),
    yield takeEvery(updateCustomer.type, updateCustomerSaga),
    yield takeEvery(deleteCustomer.type, deleteCustomerSaga)
  ])
};