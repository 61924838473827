import { takeEvery, all, call, put } from 'redux-saga/effects';
import { driverSlice } from './../store';
import { DriverService } from './../services';

const { createDriver, createDriverFailure, fetchAllDrivers, fetchAllDriversSuccess, fetchAllDriversFailure, updateDriver, updateDriverFailure, deleteDriver, deleteDriverFailure } = driverSlice.actions;

function* fetchDriversSaga() {
  try {
    const drivers = (yield call(DriverService.getAllActiveDrivers, 'driver')).data;
    yield put(fetchAllDriversSuccess(drivers));
  } catch(ex) {
    yield put(fetchAllDriversFailure(ex));
  }
}

function* createDriverSaga(action) {
    try {
      yield call(DriverService.createNewDriver, action.payload.data);
      const drivers = (yield call(DriverService.getAllActiveDrivers, 'driver')).data;
      yield put(fetchAllDriversSuccess(drivers));
      yield call(DriverService.createNewDriverAsStaff, action.payload.data)
    } catch(ex) {
      yield put(createDriverFailure(ex));
    }
  }

function* updateDriverSaga(action) {
  try {
    yield call(DriverService.updateDriver, action.payload.id, action.payload.data);
    const drivers = (yield call(DriverService.getAllActiveDrivers, 'driver')).data;
    yield put(fetchAllDriversSuccess(drivers));
    const existedStaffs = (yield DriverService.getStaffsByNameOrEmail(action.payload.currentEmployee.email)).data;
    if (existedStaffs && existedStaffs.length > 0) {
      const staffData = Object.assign({}, action.payload.data );
      delete staffData.address; 
      yield call(DriverService.updateDriverInStaff(existedStaffs[0].id, staffData))
    }
  } catch(ex) {
    yield put(updateDriverFailure(ex));
  }
}

function* deleteDriverSaga(action) {
  try {
    yield call(DriverService.deleteDriver, action.payload.id, action.payload.data);
    const drivers = (yield call(DriverService.getAllActiveDrivers, 'driver')).data;
    yield put(fetchAllDriversSuccess(drivers));
  } catch(ex) {
    yield put(updateDriverFailure(ex));
  }
}

export function* driverEffect() {
  yield all([
    yield takeEvery(fetchAllDrivers.type, fetchDriversSaga),
    yield takeEvery(createDriver.type, createDriverSaga),
    yield takeEvery(updateDriver.type, updateDriverSaga),
    yield takeEvery(deleteDriver.type, deleteDriverSaga)
  ])
};