import { createSlice, CaseReducer } from "@reduxjs/toolkit";

const initialState = {
  drivers: [],
  loading: false,
  error: null
};


const fetchAllDrivers = (state, action) => {
  state.loading = true;
}
const fetchAllDriversSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.drivers = action.payload;
}
const fetchAllDriversFailure = (state, action) => {
  state.drivers = [];
  state.error = action.payload;
}
const createDriver = (state, action) => {}
const createDriverFailure = (state, action) => {
  state.error = action.payload;
}

const deleteDriver = (state, action) => {}
const deleteDriverFailure = (state, action) => {
  state.error = action.payload;
}

const updateDriver = (state, action) => {}
const updateDriverFailure = (state, action) => {
  state.error = action.payload;
}

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    fetchAllDrivers,
    fetchAllDriversSuccess,
    fetchAllDriversFailure,
    createDriver,
    createDriverFailure,
    updateDriver,
    updateDriverFailure,
    deleteDriver,
    deleteDriverFailure
  }
});

