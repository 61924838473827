import React, {useState, useEffect} from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { customerSlice } from "./../../store";
import { AuthService, ResourceService } from "../../services";

const ViewResource = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [currentResource, setCurrentResource] =  useState(undefined);
  
  const params = new URLSearchParams(window.location.search);
  
  const redirectTo = (id) => {
    navigate(`/medical/resources/list`);
  }

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    ResourceService.getResource(urlParams.id).then(resourceData => {
      setCurrentResource(resourceData.data);
    })
  }, []);

  return (
    <>
      <div className="list row">
        <div className="col-md-12 col-xs-12">
          <div className="list row mb-4">
            <div className="col-md-12 text-primary">
              <h5>View Resource Details<button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back To List</button></h5> 
            </div>
          </div>
          <div className="list row mb-4">
            <div className="col-md-4 mb-4">
              <div>Name:</div> {currentResource?.name}
            </div>
            <div className="col-md-4 mb-4">
              <div>Specialty:</div>
              {currentResource?.specialty}
            </div>
            <div className="col-md-4 mb-4">
              <div>type:</div> 
              {currentResource?.type}
            </div>
            {/* <div className="col-md-4 mb-4">
              <div>Category:</div>
              {currentResource?.category}
            </div> */}
            <div className="col-md-4 mb-4">
              <div>Description:</div>
              {currentResource?.description}
            </div>
            <div className="col-md-4 mb-4">
              <div>Color:</div> 
              {currentResource?.color}
            </div>
            <div className="col-md-4 mb-4">
              <div>Address: </div>
              {currentResource?.address}
            </div>
            <div className="col-md-4 mb-4">
              <div>Contact: </div>
              {currentResource?.contact}
            </div>
            <div className="col-md-4 mb-4">
              <div>Phone:</div>
              {currentResource?.phone}
            </div>
            <div className="col-md-4 mb-4">
              <div>Fax:</div>
              {currentResource?.fax}
            </div>
            <div className="col-md-4 mb-4">
              <div>Note:</div>
              {currentResource?.note}
            </div>
            <div className="col-md-4 mb-4">
              <div>Status:</div>
              {currentResource?.status}
            </div>
          </div>
         
        </div>
      </div>
        
    </>
  );
};

export default ViewResource;