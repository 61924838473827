import React, {useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService, VehicleService } from "../../services";

const ViewVehicle = () => {
  const navigate = useNavigate();

  const urlParams = useParams();
  const [currentVehicle, setCurrentVehicle] =  useState(undefined);
	
  const redirectTo = () => {
		navigate(`/vehicles/list`)
  }

  useEffect(() => {
    if (!AuthService.canViewVechiles()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    if (!currentVehicle) {
      VehicleService.getVehicle(urlParams.id).then((data) => {
        setCurrentVehicle(data.data);
      })
    }
  }, []);
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>View Vechile {currentVehicle?.vehicle_number} <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-4 mb-4">
          <div>Vehicle Number: {currentVehicle?.vehicle_number}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Tag: {currentVehicle?.tag}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>GPS: {currentVehicle?.gps_tag}</div> 
        </div>
				<div className="col-md-4 mb-4">
			  	<div>EZPass: {currentVehicle?.ezpass}</div> 
        </div>
        <div className="col-md-4 mb-4">
          <div>Capacity: {currentVehicle?.capacity}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Mileage: {currentVehicle?.mileage}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Make: {currentVehicle?.make}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Model: {currentVehicle?.vehicle_model}</div> 
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Year: {currentVehicle?.year}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Checklist: {currentVehicle?.checklist?.join(', ')}</div> 
        </div>
      </div>
    </>
  );
};

export default ViewVehicle;