import { all } from "redux-saga/effects";
import { userEffect } from "./user.saga";
import { transRoutesEffect } from './trans-routes.saga';
import { driverEffect } from './driver.saga';
import { vehicleEffect } from "./vehicle.saga";
import { employeeEffect} from './employee.saga';
import { customerEffect } from "./customer.saga";
import { transRoutesTemplateEffect } from "./trans-route-templates.saga";

export function* rootSaga() {
	yield all([
		userEffect(),
		transRoutesEffect(),
		driverEffect(),
		vehicleEffect(),
		employeeEffect(),
		transRoutesTemplateEffect(),
		customerEffect()
	]);
}